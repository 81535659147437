import {Component, Input, OnInit} from '@angular/core';
import {ChatAnswersUserModel} from "../../models/chat/chatAnswers.model";
import {PHONE_MASK} from "../../config/country_phone_mask";

@Component({
    selector: 'app-user-info',
    templateUrl: './user-info.component.html',
    styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {
    @Input() userInfo: ChatAnswersUserModel;
    PHONE_MASK = PHONE_MASK;
    constructor() {}

    ngOnInit() {}
}
