import {Component, OnInit} from '@angular/core';
import {slideToLeft} from "../../animations/router.animations";
import {LoaderService} from "../../service/loader.service";
import {PopupComponent} from "../../shared/popup/popup.component";
import {UserChatService} from "../../service/user-chat/user-chat.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ExampleHeader} from "../../shared/datapicker-header/datapicker-header";
import {TIME_SPANS} from "../../config/timeSpans.config";
import {ProvidersService} from "../../service/providers.service";
import {SupportService} from "../../service/support/support.service";
import {ProviderModel, ProvidersListResponseModel} from "../../models/providers/providersListResponse.model";
import {SupportResponseModel} from "../../models/support/supportResponse.model";

@Component({
    selector: 'app-new-ticket',
    templateUrl: './new-ticket.component.html',
    styleUrls: ['./new-ticket.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class NewTicketComponent implements OnInit {
    supportForm: FormGroup;
    formKeys = {
        system: 'system',
        date: 'date',
        time: 'time',
        description: 'description'
    }
    exampleHeader = ExampleHeader;
    times = TIME_SPANS;
    selectedTime;
    selectedSystem;
    providers: Array<ProviderModel> = [];

    constructor(private formBuilder: FormBuilder,
                private loaderService: LoaderService,
                private providersService: ProvidersService,
                private userChatService: UserChatService,
                private supportService: SupportService,
                private popupComponent: PopupComponent) {

    }

    ngOnInit() {
        this.setForm();
        this.getProviders();
    }

    setForm(): void {
        this.supportForm = this.formBuilder.group({
            [this.formKeys.system]: ['', Validators.required],
            [this.formKeys.date]: ['', Validators.required],
            [this.formKeys.time]: ['', Validators.required],
            [this.formKeys.description]: ['', Validators.required],
        });
    }

    getProviders(): void {
        this.providersService.getProviders().subscribe(data => {
            this.setProviders(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    setProviders(data: ProvidersListResponseModel): void {
        if (data.status) {
            this.providers = data.result;
            this.setSupportSelects();
        } else {
            this.popupComponent.showPopupError({text: data.message});
        }
    }

    resetForm(): void {
        this.supportForm.reset();
        this.setSupportSelects();
    }

    onSubmitSupportForm(): void {
        if (!this.isFormValid()) {
            return
        }

        const system = this.getSupportFormFieldValue(this.formKeys.system).id;
        const date = this.getSupportDate();
        const time = this.getSupportFormFieldValue(this.formKeys.time);
        const description = this.getSupportFormFieldValue(this.formKeys.description);

        if (system && date && time && description) {
            this.sendSupportRequest(system, date, time, description);
        }
    }

    sendSupportRequest(system, date, time, description): void {
        if (system && date && time && description) {
            this.supportService.sendSupportRequest(system, date, time, description).subscribe(data => {
                this.showSendSupportResult(data);
            }, () => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    showSendSupportResult(data: SupportResponseModel) {
        try {
            if (data.status) {
                this.popupComponent.showPopupSuccess({text: data.message});
                this.goBack();
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    getSupportFormFieldValue(name: string): any {
        if (this.supportForm && this.supportForm.controls[name]) {
            return this.supportForm.controls[name].value;
        } else {
            return null;
        }
    }

    getSupportDate(): string {
        return (<HTMLInputElement>document.querySelector('[formcontrolname=date]')).value;
    }

    setSupportSelects(): void {
        this.selectedSystem = this.providers[0];
        this.selectedTime = this.times[0];
        this.supportForm.controls.time.setValue(this.selectedTime);

        this.supportForm.controls.system.setValue(this.selectedSystem);
    }

    goBack(): void {
        this.resetForm();
        this.userChatService.backToUserChat();
    }

    isFormValid(): boolean {
        return this.supportForm.valid;
    }

}
