import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {slideToLeft} from "../../animations/router.animations";
import {LoaderService} from "../../service/loader.service";
import {PopupComponent} from "../../shared/popup/popup.component";
import {UserChatService} from "../../service/user-chat/user-chat.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {DATE_MASK} from "../../config/date_mask";
import {ChatQuestionsQuestionModel} from "../../models/chat/chatQuestions.model";
import {QUESTION_STATUS, QUESTION_TYPE} from "../../config/question_type.config";
import {UserChatQuestionInfoModel} from "../../models/user-chat/userChatInfoData.model";
import {ChatAnswersAnswerModel} from "../../models/chat/chatAnswers.model";
import {PHONE_MASK} from "../../config/country_phone_mask";
import {RouterPaths} from "../../config/router-paths.model";
import {RefreshCustomerService} from "../../service/refreshCustomer.service";
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";

@Component({
    selector: 'app-user-chat',
    templateUrl: './user-chat.component.html',
    styleUrls: ['./user-chat.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class UserChatComponent implements OnInit {
    sessionId;
    questionsList: Array<ChatQuestionsQuestionModel>;
    DATE_MASK = DATE_MASK;
    QUESTION_TYPE = QUESTION_TYPE;
    questionInfo: UserChatQuestionInfoModel;
    selectedQuestion: ChatQuestionsQuestionModel;
    activeQuestion: Array<ChatAnswersAnswerModel>;
    showQuestion = false;
    activeQuestionUuid: string;
    phoneMask = PHONE_MASK.us;

    @ViewChild('scrolled', {static: false}) scrolled: ElementRef;


    refreshSubscription;
    constructor(private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private refreshCustomerService: RefreshCustomerService,
                private userChatService: UserChatService,
                private router: Router,
                private authenticationService: AuthenticationService,
                private activatedRoute: ActivatedRoute) {

        this.activatedRoute.params.subscribe((params: Params) => {
            this.sessionId = params.id;
            this.authenticationService.setSessionId(this.sessionId);
            this.showPageInfo();
        })
    }

    ngOnInit() {
        this.refreshSubscription = this.refreshCustomerService.emitRefresh.subscribe(val => {
            if (val !== null) {
                if (this.selectedQuestion) {
                    this.openQuestion(this.selectedQuestion, true);
                }
            }
        });
        // document.body.classList.add('is-transparent');
    }

    ngOnDestroy() {
        this.refreshSubscription.unsubscribe();
        // document.body.classList.remove('is-transparent');
    }

    showPageInfo() {
        if (this.sessionId) {
            this.getPageInfo();
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    getPageInfo() {
        this.loaderService.show();
        this.userChatService.getPageInfo(this.sessionId).subscribe(data => {
            this.setPageInfo(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    setPageInfo(data) {
        try {
            if (data.status) {
                if (data.role && data.role !== 'player') {
                    this.authenticationService.loginUser(data);
                    this.authenticationService.setIframeVision();
                    this.router.navigate([RouterPaths.panel.main]);
                    return;
                }
                this.questionsList = data.result;
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    refreshPage() {
        this.refreshCustomerService.refresh();
    }

    getQuestionStatus(status) {
        return QUESTION_STATUS[status];
    }

    openQuestion(question, refresh = false, loader = true) {
        if (!refresh) {
            this.refreshCustomerService.setRefreshTimer();
        }
        this.activeQuestionUuid = question.uuid;
        let full = true;
        if (refresh) {
            loader = false;
            full = false;
        }
        if (loader) {
            this.loaderService.show();
        }
        this.userChatService.getQuestionInfo(this.activeQuestionUuid, full).subscribe(data => {
            this.selectedQuestion = question;
            this.setQuestion(data, full);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    setQuestion(data, full) {
        try {
            if (data.status) {
                if (full) {
                    this.questionInfo = data.user;
                }

                if (!full) {
                    if (data.result.length !== this.activeQuestion.length) {
                        this.openDialog(data.result);
                    }
                } else {
                    this.openDialog(data.result);
                }

                // setTimeout(() => {
                //     this.checkImagesLoaded();
                // });
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    checkImagesLoaded() {
        const images = document.querySelectorAll('img');
        let imagesCounter = images.length;

        if (imagesCounter) {
            images.forEach((img, index) => {
                if (img.complete) {
                    imagesCounter -= 1;
                }
                img.addEventListener('load', () => {
                    imagesCounter -= 1;
                    // this.scrollToBottom();
                    if (!imagesCounter) {
                        setTimeout(() => {
                            this.scrollToBottom();
                            this.loaderService.hide();
                        });
                    }
                });
                img.addEventListener('error', () => {
                    imagesCounter -= 1;
                    // this.scrollToBottom();
                    if (!imagesCounter) {
                        setTimeout(() => {
                            this.scrollToBottom();
                            this.loaderService.hide();
                        });
                    }
                });
            });
        } else {
            setTimeout(() => {
                this.scrollToBottom();
                this.loaderService.hide();
            });
        }

    }

    scrollToBottom() {
        if (this.scrolled) {
            this.scrolled.nativeElement.scrollTo({
                top: this.scrolled.nativeElement.scrollHeight,
                behavior: 'smooth'
            });
            // setTimeout(() => {
            //     this.scrolled.nativeElement.scrollIntoView({block: "end"});
            // });
        }
    }

    updateDialog() {
        // setTimeout(() => {
        //     this.scrollToBottom();
        // }, 500);
        setTimeout(() => {
            this.checkImagesLoaded();
        }, 0);
    }

    openDialog(dialog) {
        this.activeQuestion = dialog;
        this.showQuestion = true;
    }

    getNewTicketLink() {
        return RouterPaths.newTicket.main;
    }
}
