<ul class="dialog" *ngIf="activeQuestion" [class.is-answer]="!getActiveQuestionStatus()" [class.is-client]="isCustomer" #scrolled>
    <app-user-info [userInfo]="userInfo" *ngIf="!isCustomer && userInfo"></app-user-info>
    <li *ngFor="let message of activeQuestion"
        class="dialog__item"
        [class.dialog__item--right]="((+message.role_id === +USER_ROLE.admin) && !isCustomer) ||
                                     ((+message.role_id === +USER_ROLE.adminRedirect) && !isCustomer) ||
                                     ((+message.role_id === +USER_ROLE.customer) && isCustomer)">
        <div class="dialog__created">{{message.created | date: DATE_MASK.full}}</div>

        <img class="dialog__img" (click)="zoomImage(message.file_link)" *ngIf="message.file_link" [src]="message.file_link" alt="">
        <div>
            <p class="dialog__message" *ngIf="message.title && message.type !== ANSWER_TYPE.file">{{message.title}}</p>
        </div>

    </li>
</ul>

<div class="answer" *ngIf="!getActiveQuestionStatus()" [class.is-user-chat]="isCustomer">
    <div *ngFor="let file of fileToUpload"  class="answer__uploaded-file">
        File: <span>{{file.name}}</span>
        <button (click)="removeFile(file)">
            <svg>
                <use xlink:href="../../../../assets/images/sprite.svg#close"></use>
            </svg>
        </button>
    </div>
    <div class="answer__inner">
        <div class="answer__upload-image">
            <input style="display: none" (change)="handleFileInput($event.target.files)" #addImage multiple accept="image/*" type='file' name='filename' hidden/>
            <button (click)="addImage.click()" type="button">
                <svg>
                    <use xlink:href="../../../../assets/images/sprite.svg#image"></use>
                </svg>
            </button>
        </div>
        <input class="answer__input" (paste)="screenShort($event)" (keyup.enter)="sendMessage()" type='text' #message placeholder="Type a message..."/>
        <button class="answer__submit" (click)="sendMessage()" [disabled]="!fileToUpload && !message.value">Send</button>
    </div>
</div>
