import { PROVIDERS_CONFIG } from "../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./loader.service";
var ProvidersService = /** @class */ (function () {
    function ProvidersService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        this.config = PROVIDERS_CONFIG;
    }
    ProvidersService.prototype.getProviders = function () {
        this.loaderService.show();
        return this.http.post(this.config.getList, {});
    };
    ProvidersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProvidersService_Factory() { return new ProvidersService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: ProvidersService, providedIn: "root" });
    return ProvidersService;
}());
export { ProvidersService };
