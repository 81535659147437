import { PANEL_INFO_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var PanelInfoService = /** @class */ (function () {
    function PanelInfoService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        this.config = PANEL_INFO_CONFIG;
    }
    PanelInfoService.prototype.getPageInfo = function () {
        // this.loaderService.show();
        return this.http.post(this.config.info, {});
    };
    PanelInfoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PanelInfoService_Factory() { return new PanelInfoService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: PanelInfoService, providedIn: "root" });
    return PanelInfoService;
}());
export { PanelInfoService };
