import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';
import { AuthConfig } from './auth-config';
import { AUTH_CONFIG } from '../../../config/api';
import * as i0 from "@angular/core";
import * as i1 from "./auth-config";
import * as i2 from "@angular/common/http";
import * as i3 from "../../../service/refresh.service";
import * as i4 from "../../../service/loader.service";
import * as i5 from "../user-idle/user-idle.service";
import * as i6 from "@angular/router";
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(authConfig, http, refreshService, loaderService, userIdle, router) {
        this.authConfig = authConfig;
        this.http = http;
        this.refreshService = refreshService;
        this.loaderService = loaderService;
        this.userIdle = userIdle;
        this.router = router;
        this.config = AUTH_CONFIG;
        this.storage = sessionStorage;
        this.storageKeys = {
            currentUser: 'currentUser',
            sessionId: 'sessionId'
        };
        this.rolesObservable = new EventEmitter();
        this.userLoggedIn = new EventEmitter();
    }
    AuthenticationService.prototype.isUserLogged = function () {
        return !!this.storage.getItem(this.storageKeys.currentUser);
    };
    AuthenticationService.prototype.login = function (login, password) {
        var _this = this;
        this.loaderService.show();
        return this.http.post(this.config.login, { login: login, password: password })
            .pipe(map(function (res) {
            if (res.status) {
                _this.loginUser(res);
            }
            return res;
        }));
    };
    AuthenticationService.prototype.loginUser = function (res) {
        this.setData(res);
        this.userLoggedIn.emit(true);
    };
    AuthenticationService.prototype.setData = function (result) {
        this.storage = sessionStorage;
        this.storage.setItem(this.storageKeys.currentUser, JSON.stringify({
            token: result.token,
            firstName: result.firstName,
            lastName: result.lastName
        }));
    };
    AuthenticationService.prototype.logout = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.refreshService.stopRefresh();
                        this.storage.removeItem(this.storageKeys.currentUser);
                        return [4 /*yield*/, this.checkSession()];
                    case 1:
                        _a.sent();
                        this.userLoggedIn.emit(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthenticationService.prototype.setStamp = function () {
        var now = Date.now();
        this.authStamp = now;
        var $this = this;
        setTimeout(function () {
            $this.setStamp();
        }, 1000);
    };
    AuthenticationService.prototype.checkStamp = function () {
        if (this.storage.getItem(this.storageKeys.currentUser) && !this.storage.getItem(this.storageKeys.sessionId)) {
            var now = Date.now();
            if (this.authStamp) {
                if (now > (this.authStamp + 3000000)) {
                    this.logout();
                    this.router.navigate(['/']);
                    return;
                }
                else {
                    this.refreshTokenNow();
                }
            }
            else {
                this.refreshTokenNow();
            }
        }
    };
    AuthenticationService.prototype.refreshTokenNow = function () {
        var _this = this;
        if (this.storage.getItem(this.storageKeys.currentUser) && !this.storage.getItem(this.storageKeys.sessionId)) {
            this.http.post(this.config.refreshToken, {}).subscribe(function (res) {
                if (res.status) {
                    _this.setData(res.result);
                }
                else {
                    _this.router.navigate(['/']);
                }
                return res;
            });
        }
        return false;
    };
    AuthenticationService.prototype.refreshToken = function () {
        var $this = this;
        setTimeout(function () {
            $this.refreshToken();
        }, 60 * 10 * 1000);
        this.refreshTokenNow();
    };
    AuthenticationService.prototype.checkSession = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    AuthenticationService.prototype.getCurrentUser = function () {
        if (sessionStorage.getItem(this.storageKeys.currentUser)) {
            try {
                var currentUser = JSON.parse(sessionStorage.getItem(this.storageKeys.currentUser));
                return currentUser;
            }
            catch (e) {
            }
        }
        return false;
    };
    AuthenticationService.prototype.getUserFirstName = function () {
        if (this.getCurrentUser()) {
            try {
                var currentUser = this.getCurrentUser();
                return currentUser.firstName;
            }
            catch (e) {
            }
        }
        return '';
    };
    AuthenticationService.prototype.getUserLastName = function () {
        if (this.getCurrentUser()) {
            try {
                var currentUser = this.getCurrentUser();
                return currentUser.lastName;
            }
            catch (e) {
            }
        }
        return '';
    };
    AuthenticationService.prototype.getUserFullName = function () {
        return this.getUserFirstName() + " " + this.getUserLastName();
    };
    AuthenticationService.prototype.getToken = function () {
        if (this.getCurrentUser()) {
            try {
                var currentUser = this.getCurrentUser();
                return currentUser.token;
            }
            catch (e) {
            }
        }
        return '';
    };
    AuthenticationService.prototype.setSessionId = function (sessionId) {
        if (sessionId) {
            this.storage.setItem(this.storageKeys.sessionId, sessionId);
        }
        else {
            this.storage.removeItem(this.storageKeys.sessionId);
        }
    };
    AuthenticationService.prototype.getSessionId = function () {
        if (this.storage.getItem(this.storageKeys.sessionId)) {
            return this.storage.getItem(this.storageKeys.sessionId);
        }
        return false;
    };
    AuthenticationService.prototype.setIframeVision = function () {
        localStorage.setItem('hideLogout', '1');
    };
    AuthenticationService.prototype.isIframeVision = function () {
        return localStorage.getItem('hideLogout') === '1';
    };
    AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.AuthConfig), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.RefreshService), i0.ɵɵinject(i4.LoaderService), i0.ɵɵinject(i5.UserIdleService), i0.ɵɵinject(i6.Router)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
