import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "../shared/popup/popup.component";
var RefreshCustomerService = /** @class */ (function () {
    function RefreshCustomerService(popupComponent) {
        this.popupComponent = popupComponent;
        this.emitRefresh = new EventEmitter(null);
        this.refreshAfterMin = 1;
        this.refreshAfterSec = this.refreshAfterMin * 60;
    }
    RefreshCustomerService.prototype.setRefreshTimer = function () {
        var _this = this;
        // console.log('set refresh');
        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
        }
        this.refreshTimeout = setTimeout(function () {
            _this.refresh();
        }, this.refreshAfterSec * 1000);
    };
    RefreshCustomerService.prototype.refresh = function () {
        // console.log('refresh');
        this.emitRefresh.emit(Math.round(+new Date() / 1000) + Math.random());
        this.setRefreshTimer();
    };
    RefreshCustomerService.prototype.stopRefresh = function () {
        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
        }
    };
    RefreshCustomerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RefreshCustomerService_Factory() { return new RefreshCustomerService(i0.ɵɵinject(i1.PopupComponent)); }, token: RefreshCustomerService, providedIn: "root" });
    return RefreshCustomerService;
}());
export { RefreshCustomerService };
