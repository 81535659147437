/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./panel.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../shared/panel-header/panel-header.component.ngfactory";
import * as i4 from "../../shared/panel-header/panel-header.component";
import * as i5 from "../../modules/auth/_services/authentication.service";
import * as i6 from "../../service/mobile-menu.service";
import * as i7 from "@angular/router";
import * as i8 from "../../shared/chat/chat.component.ngfactory";
import * as i9 from "../../shared/chat/chat.component";
import * as i10 from "../../service/chat/chat.service";
import * as i11 from "../../service/refresh.service";
import * as i12 from "../../shared/popup/popup.component";
import * as i13 from "../../service/loader.service";
import * as i14 from "./panel.component";
import * as i15 from "../../service/panel-info/panel-info.service";
var styles_PanelComponent = [i0.styles];
var RenderType_PanelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PanelComponent, data: { "animation": [{ type: 7, name: "slideToLeft", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { position: "fixed", width: "100%" }, offset: null }, options: undefined }, { type: 0, name: "*", styles: { type: 6, styles: { position: "fixed", width: "100%" }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: [{ type: 6, styles: { transform: "translateX(100%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(0%)" }, offset: null }, timings: "0.5s ease-in-out" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { transform: "translateX(0%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(100%)" }, offset: null }, timings: "0.5s ease-in-out" }], options: null }], options: {} }] } });
export { RenderType_PanelComponent as RenderType_PanelComponent };
function View_PanelComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "users-list__item"]], [[2, "is-active", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "users-list__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showUserQuestions(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", " ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.user_id === _co.activeUserId); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.first_name; var currVal_2 = _v.context.$implicit.last_name; _ck(_v, 2, 0, currVal_1, currVal_2); }); }
function View_PanelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "panel__block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "panel__subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Open "])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "ul", [["class", "panel__users-list users-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PanelComponent_2)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.usersNew; _ck(_v, 5, 0, currVal_0); }, null); }
function View_PanelComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "users-list__item"]], [[2, "is-active", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "users-list__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showUserQuestions(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", " ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.user_id === _co.activeUserId); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.first_name; var currVal_2 = _v.context.$implicit.last_name; _ck(_v, 2, 0, currVal_1, currVal_2); }); }
function View_PanelComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "panel__block"]], [[2, "panel__block--separator", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "panel__subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Closed "])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "ul", [["class", "panel__users-list users-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PanelComponent_4)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.usersOld; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.usersNew.length; _ck(_v, 0, 0, currVal_0); }); }
export function View_PanelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "panel-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-panel-header", [], null, [[null, "refresh"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("refresh" === en)) {
        var pd_0 = (_co.refreshPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_PanelHeaderComponent_0, i3.RenderType_PanelHeaderComponent)), i1.ɵdid(2, 114688, null, 0, i4.PanelHeaderComponent, [i5.AuthenticationService, i6.MobileMenuService, i7.Router], { userName: [0, "userName"] }, { refresh: "refresh" }), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "panel__left"]], [[2, "is-visible", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PanelComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PanelComponent_3)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "panel__right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-chat", [], null, [[null, "onRefresh"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onRefresh" === en)) {
        var pd_0 = (_co.refreshPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ChatComponent_0, i8.RenderType_ChatComponent)), i1.ɵdid(11, 245760, null, 0, i9.ChatComponent, [i10.ChatService, i11.RefreshService, i12.PopupComponent, i13.LoaderService], { activeUserId: [0, "activeUserId"], activeUser: [1, "activeUser"] }, { emitRefresh: "onRefresh" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getUserName(); _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.usersNew.length; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.usersOld.length; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.activeUserId; var currVal_5 = _co.activeUser; _ck(_v, 11, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showMobileMenu; _ck(_v, 4, 0, currVal_1); }); }
export function View_PanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-panel", [], [[40, "@slideToLeft", 0]], null, null, View_PanelComponent_0, RenderType_PanelComponent)), i1.ɵdid(1, 245760, null, 0, i14.PanelComponent, [i13.LoaderService, i12.PopupComponent, i11.RefreshService, i6.MobileMenuService, i5.AuthenticationService, i15.PanelInfoService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
var PanelComponentNgFactory = i1.ɵccf("app-panel", i14.PanelComponent, View_PanelComponent_Host_0, {}, {}, []);
export { PanelComponentNgFactory as PanelComponentNgFactory };
