import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {LoaderService} from "./loader.service";
import {PROVIDERS_CONFIG} from "../config/api";


@Injectable({
  providedIn: 'root'
})
export class ProvidersService {
  private config = PROVIDERS_CONFIG;

  constructor(private http: HttpClient,
              public loaderService: LoaderService) {}

  getProviders() {
    this.loaderService.show();
    return this.http.post<any>(this.config.getList, {});
  }
}
