import { OnInit } from '@angular/core';
import { Validators } from "@angular/forms";
import { ExampleHeader } from "../../shared/datapicker-header/datapicker-header";
import { TIME_SPANS } from "../../config/timeSpans.config";
var NewTicketComponent = /** @class */ (function () {
    function NewTicketComponent(formBuilder, loaderService, providersService, userChatService, supportService, popupComponent) {
        this.formBuilder = formBuilder;
        this.loaderService = loaderService;
        this.providersService = providersService;
        this.userChatService = userChatService;
        this.supportService = supportService;
        this.popupComponent = popupComponent;
        this.formKeys = {
            system: 'system',
            date: 'date',
            time: 'time',
            description: 'description'
        };
        this.exampleHeader = ExampleHeader;
        this.times = TIME_SPANS;
        this.providers = [];
    }
    NewTicketComponent.prototype.ngOnInit = function () {
        this.setForm();
        this.getProviders();
    };
    NewTicketComponent.prototype.setForm = function () {
        var _a;
        this.supportForm = this.formBuilder.group((_a = {},
            _a[this.formKeys.system] = ['', Validators.required],
            _a[this.formKeys.date] = ['', Validators.required],
            _a[this.formKeys.time] = ['', Validators.required],
            _a[this.formKeys.description] = ['', Validators.required],
            _a));
    };
    NewTicketComponent.prototype.getProviders = function () {
        var _this = this;
        this.providersService.getProviders().subscribe(function (data) {
            _this.setProviders(data);
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    NewTicketComponent.prototype.setProviders = function (data) {
        if (data.status) {
            this.providers = data.result;
            this.setSupportSelects();
        }
        else {
            this.popupComponent.showPopupError({ text: data.message });
        }
    };
    NewTicketComponent.prototype.resetForm = function () {
        this.supportForm.reset();
        this.setSupportSelects();
    };
    NewTicketComponent.prototype.onSubmitSupportForm = function () {
        if (!this.isFormValid()) {
            return;
        }
        var system = this.getSupportFormFieldValue(this.formKeys.system).id;
        var date = this.getSupportDate();
        var time = this.getSupportFormFieldValue(this.formKeys.time);
        var description = this.getSupportFormFieldValue(this.formKeys.description);
        if (system && date && time && description) {
            this.sendSupportRequest(system, date, time, description);
        }
    };
    NewTicketComponent.prototype.sendSupportRequest = function (system, date, time, description) {
        var _this = this;
        if (system && date && time && description) {
            this.supportService.sendSupportRequest(system, date, time, description).subscribe(function (data) {
                _this.showSendSupportResult(data);
            }, function () {
                _this.loaderService.hideAndShowTryAgain();
            }, function () {
                _this.loaderService.hide();
            });
        }
        else {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    NewTicketComponent.prototype.showSendSupportResult = function (data) {
        try {
            if (data.status) {
                this.popupComponent.showPopupSuccess({ text: data.message });
                this.goBack();
            }
            else {
                this.popupComponent.showPopupError({ text: data.message });
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    NewTicketComponent.prototype.getSupportFormFieldValue = function (name) {
        if (this.supportForm && this.supportForm.controls[name]) {
            return this.supportForm.controls[name].value;
        }
        else {
            return null;
        }
    };
    NewTicketComponent.prototype.getSupportDate = function () {
        return document.querySelector('[formcontrolname=date]').value;
    };
    NewTicketComponent.prototype.setSupportSelects = function () {
        this.selectedSystem = this.providers[0];
        this.selectedTime = this.times[0];
        this.supportForm.controls.time.setValue(this.selectedTime);
        this.supportForm.controls.system.setValue(this.selectedSystem);
    };
    NewTicketComponent.prototype.goBack = function () {
        this.resetForm();
        this.userChatService.backToUserChat();
    };
    NewTicketComponent.prototype.isFormValid = function () {
        return this.supportForm.valid;
    };
    return NewTicketComponent;
}());
export { NewTicketComponent };
