import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter, OnInit } from '@angular/core';
import { USER_ROLE } from "../../config/userRole.config";
import { DATE_MASK } from "../../config/date_mask";
import { ANSWER_TYPE } from "../../config/answer_type.config";
import * as panzoom from "panzoom";
var DialogComponent = /** @class */ (function () {
    function DialogComponent(loaderService, popupComponent, chatService) {
        this.loaderService = loaderService;
        this.popupComponent = popupComponent;
        this.chatService = chatService;
        this.isCustomer = false;
        this.USER_ROLE = USER_ROLE;
        this.DATE_MASK = DATE_MASK;
        this.ANSWER_TYPE = ANSWER_TYPE;
        this.fileToUpload = [];
        this.messageIsSending = false;
        this.updateDialog = new EventEmitter();
    }
    Object.defineProperty(DialogComponent.prototype, "activeQuestion", {
        get: function () {
            return this._activeQuestion;
        },
        set: function (value) {
            var _this = this;
            if (this.activeQuestion && value.length && value[0].question_uuid === this.activeQuestion[0].question_uuid) {
                var newAnswersList = value ? value : [];
                newAnswersList.forEach(function (answer) {
                    var newAnswer = _this.activeQuestion.filter(function (oldAnswer) { return oldAnswer.id === answer.id; });
                    if (!newAnswer.length) {
                        _this.activeQuestion.push(answer);
                        setTimeout(function () {
                            _this.initScroll();
                        });
                    }
                });
                this.initScroll();
            }
            else {
                this._activeQuestion = value;
                this.initScroll();
            }
        },
        enumerable: true,
        configurable: true
    });
    DialogComponent.prototype.ngOnInit = function () {
    };
    DialogComponent.prototype.handleFileInput = function (files) {
        var _this = this;
        var filesArray = Array.from(files);
        filesArray.forEach(function (newFile) {
            var unique = true;
            _this.fileToUpload.forEach(function (existFile) {
                if (newFile.lastModified === existFile.lastModified) {
                    unique = false;
                }
            });
            if (unique) {
                _this.fileToUpload.push(newFile);
            }
        });
    };
    DialogComponent.prototype.removeFile = function (file) {
        var id = file.lastModified;
        this.fileToUpload = this.fileToUpload.filter(function (file) { return file.lastModified !== id; });
    };
    DialogComponent.prototype.sendMessage = function () {
        var _this = this;
        var message = this.message.nativeElement.value;
        var files = this.fileToUpload;
        var questionId = this.activeQuestionUuid;
        var userId = this.activeUserId;
        if ((files.length || message) && !this.messageIsSending) {
            this.messageIsSending = true;
            if (!this.isCustomer) {
                this.chatService.addFile(files, message, userId, questionId).subscribe(function (data) {
                    if (data['body']) {
                        _this.sendMessageResult(data);
                    }
                }, function () {
                    _this.loaderService.hideAndShowTryAgain();
                }, function () {
                    _this.loaderService.hide();
                    _this.messageIsSending = false;
                });
            }
            else {
                this.chatService.addFileCustomer(files, message, questionId).subscribe(function (data) {
                    if (data['body']) {
                        _this.sendMessageResult(data);
                    }
                }, function () {
                    _this.loaderService.hideAndShowTryAgain();
                }, function () {
                    _this.loaderService.hide();
                    _this.messageIsSending = false;
                });
            }
        }
    };
    DialogComponent.prototype.sendMessageResult = function (data) {
        try {
            if (data['body'] && data['body'].status) {
                this.addNewMessage(data['body'].result);
            }
            else {
                this.popupComponent.showPopupError({ text: data['body'].message });
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    DialogComponent.prototype.addNewMessage = function (list) {
        var _this = this;
        if (this.activeQuestion) {
            var newAnswersList = list ? list : [];
            newAnswersList.forEach(function (answer) {
                var newAnswer = _this.activeQuestion.filter(function (oldAnswer) { return oldAnswer.id === answer.id; });
                if (!newAnswer.length) {
                    _this.activeQuestion.push(answer);
                }
            });
        }
        else {
            this.activeQuestion = list;
        }
        this.updateDialog.emit();
        this.fileToUpload = [];
        this.message.nativeElement.value = "";
        setTimeout(function () {
            _this.scrollToBottom();
            _this.checkImagesLoaded();
        }, 500);
    };
    DialogComponent.prototype.checkImagesLoaded = function () {
        var _this = this;
        var images = document.querySelectorAll('img');
        var imagesCounter = images.length;
        if (imagesCounter) {
            images.forEach(function (img, index) {
                if (img.complete) {
                    imagesCounter -= 1;
                }
                img.addEventListener('load', function () {
                    imagesCounter -= 1;
                    // this.scrollToBottom();
                    if (!imagesCounter) {
                        setTimeout(function () {
                            _this.scrollToBottom();
                            _this.loaderService.hide();
                        });
                    }
                });
                img.addEventListener('error', function () {
                    imagesCounter -= 1;
                    // this.scrollToBottom();
                    if (!imagesCounter) {
                        setTimeout(function () {
                            _this.scrollToBottom();
                            _this.loaderService.hide();
                        });
                    }
                });
            });
        }
        else {
            setTimeout(function () {
                _this.scrollToBottom();
                _this.loaderService.hide();
            });
        }
    };
    DialogComponent.prototype.scrollToBottom = function () {
        var _this = this;
        setTimeout(function () {
            if (_this.scrolled) {
                _this.scrolled.nativeElement.scrollTo({
                    top: _this.scrolled.nativeElement.scrollHeight,
                    behavior: 'smooth'
                });
            }
        }, 0);
    };
    DialogComponent.prototype.getActiveQuestionTitle = function () {
        return this.selectedQuestion.title;
    };
    DialogComponent.prototype.getActiveQuestionUuid = function () {
        return this.selectedQuestion.uuid;
    };
    DialogComponent.prototype.getActiveQuestionStatus = function () {
        return +this.selectedQuestion.status;
    };
    DialogComponent.prototype.screenShort = function (e) {
        var e_1, _a;
        var items = (e.clipboardData || e.originalEvent.clipboardData).items;
        var blob = null;
        try {
            for (var items_1 = tslib_1.__values(items), items_1_1 = items_1.next(); !items_1_1.done; items_1_1 = items_1.next()) {
                var item = items_1_1.value;
                if (item.type.indexOf('image') === 0) {
                    blob = item.getAsFile();
                    this.fileToUpload.push(blob);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (items_1_1 && !items_1_1.done && (_a = items_1.return)) _a.call(items_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    DialogComponent.prototype.zoomImage = function (link) {
        var wrapper = document.createElement("div");
        wrapper.classList.add('zoom');
        wrapper.classList.add('is-active');
        var img = document.createElement("img");
        img.classList.add('zoom__img');
        img.src = link;
        var close = document.createElement("button");
        close.classList.add('zoom__close');
        close.addEventListener('click', function (e) {
            document.body.removeChild(wrapper);
            if (document.querySelector('.panel-wrapper')) {
                document.querySelector('.panel-wrapper').classList.remove('is-blur');
            }
            if (document.querySelector('.user-chat')) {
                document.querySelector('.user-chat').classList.remove('is-blur');
            }
        });
        close.addEventListener('touchstart', function (e) {
            document.body.removeChild(wrapper);
            if (document.querySelector('.panel-wrapper')) {
                document.querySelector('.panel-wrapper').classList.remove('is-blur');
            }
            if (document.querySelector('.user-chat')) {
                document.querySelector('.user-chat').classList.remove('is-blur');
            }
        });
        setTimeout(function () {
            var pan = panzoom.default(img, {
                autocenter: true,
            });
            img.onload = function () {
                img.style.opacity = '1';
                pan.moveTo((window.innerWidth - img.offsetWidth) / 2, (window.innerHeight - img.offsetHeight) / 2);
            };
            pan.on('transform', function (e) {
                var elWidth = img.offsetWidth;
                var elHeight = img.offsetHeight;
                var elScale = pan.getTransform().scale;
                var elX = pan.getTransform().x;
                var elY = pan.getTransform().y;
                var elMaxX = 1;
                var elMinX = -(elWidth * elScale - window.innerWidth);
                var elMaxY = 1;
                var elMinY = -(elHeight * elScale - window.innerHeight);
                var needChange = false;
                var elNewX = pan.getTransform().x;
                var elNewY = pan.getTransform().y;
                if (elWidth * elScale > window.innerWidth) {
                    if (elX < elMaxX) {
                        if (elX < elMinX) {
                            elNewX = elMinX;
                            needChange = true;
                        }
                    }
                    else if (elX > elMaxX) {
                        elNewX = elMaxX;
                        needChange = true;
                    }
                }
                else if (elNewX !== (window.innerWidth - elWidth * elScale) / 2) {
                    elNewX = (window.innerWidth - elWidth * elScale) / 2;
                    needChange = true;
                }
                if (elHeight * elScale > window.innerHeight) {
                    if (elY < elMaxY) {
                        if (elY < elMinY) {
                            elNewY = elMinY;
                            needChange = true;
                        }
                    }
                    else if (elY > elMaxY) {
                        elNewY = elMaxY;
                        needChange = true;
                    }
                }
                else if (elNewY !== (window.innerHeight - elHeight * elScale) / 2) {
                    elNewY = (window.innerHeight - elHeight * elScale) / 2;
                    needChange = true;
                }
                if (needChange) {
                    pan.moveTo(elNewX, elNewY);
                }
            });
        }, 0);
        wrapper.appendChild(img);
        wrapper.appendChild(close);
        document.body.appendChild(wrapper);
        if (document.querySelector('.panel-wrapper')) {
            document.querySelector('.panel-wrapper').classList.add('is-blur');
        }
        if (document.querySelector('.user-chat')) {
            document.querySelector('.user-chat').classList.add('is-blur');
        }
    };
    DialogComponent.prototype.initScroll = function () {
        var _this = this;
        setTimeout(function () {
            if (_this.activeQuestion) {
                _this.updateDialog.emit();
                _this.checkImagesLoaded();
            }
        });
    };
    return DialogComponent;
}());
export { DialogComponent };
