import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {USER_CHAT_CONFIG} from "../../config/api";
import {LoaderService} from "../loader.service";
import {Router} from "@angular/router";
import {RouterPaths} from "../../config/router-paths.model";
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";

@Injectable({
    providedIn: 'root'
})
export class UserChatService {
    private config = USER_CHAT_CONFIG;

    constructor(private http: HttpClient,
                private router: Router,
                private authenticationService: AuthenticationService,
                public loaderService: LoaderService) {}

    getPageInfo(session) {
        // this.loaderService.show();
        return this.http.post<any>(this.config.info, {session});
    }

    getQuestionInfo(uuid, full) {
        // this.loaderService.show();
        return this.http.post<any>(this.config.question, {uuid, full});
    }

    backToUserChat() {
        const sessionId = this.authenticationService.getSessionId();
        if (sessionId) {
            this.router.navigate([RouterPaths.userChat.main, String(sessionId)]);
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }
}
