import * as i0 from "@angular/core";
var RememberUserService = /** @class */ (function () {
    function RememberUserService() {
    }
    RememberUserService.prototype.checkSaveUser = function (loginControl, rememberControl) {
        if (localStorage.login && this.isUserSaved()) {
            loginControl.setValue(localStorage.login);
            rememberControl.setValue(!!this.isUserSaved());
        }
    };
    RememberUserService.prototype.setSaveUser = function (login, remember) {
        localStorage.login = remember ? login : '';
        localStorage.remember_user = remember;
    };
    RememberUserService.prototype.isUserSaved = function () {
        return localStorage.remember_user;
    };
    RememberUserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RememberUserService_Factory() { return new RememberUserService(); }, token: RememberUserService, providedIn: "root" });
    return RememberUserService;
}());
export { RememberUserService };
