import {DOMAIN_API} from "./local_settings";
import {DOMAIN_DEMO_API,DOMAIN_DEMO_NAME} from "./demo_settings";

import {AuthConfig} from '../modules/auth/_services/auth-config';
import {environment} from "../../environments/environment";
import {PanelInfoRequestsModel} from "../models/panel-info/panelInfo_requests.model";
import {ChatRequestsModel} from "../models/chat/chat_requests.model";
import {UserChatRequestsModel} from "../models/user-chat/userChat_requests.model";
import {ProvidersRequestsModel} from "../models/providers/providers_requests.model";
import {SupportModel} from "../models/support/support.model";

let API = DOMAIN_API + '/chat';
if(window.location.hostname == DOMAIN_DEMO_NAME ){
    API = DOMAIN_DEMO_API + '/chat';
}

let USER = API + '/user';
if (environment.frontEndTest) {
    API = DOMAIN_API + '/pos';
    USER = DOMAIN_API + '/pos';
}

const QUESTIONS = API + '/questions';
const ANSWERS = API + '/answers';

export const AUTH_CONFIG: AuthConfig = {
    refreshToken: API + '/users/session/refresh',
    refresh: API + '/users/session/refresh',

    login: API + '/users/login',
};
if (environment.frontEndTest) {
    AUTH_CONFIG.login = API + '/jsons/find/file?name=eboxLogin';
}

export const PANEL_INFO_CONFIG: PanelInfoRequestsModel = {
    info: QUESTIONS + '/users',
};
if (environment.frontEndTest) {
    PANEL_INFO_CONFIG.info = API + '/jsons/find/file?name=eboxChatPanelInfo';
}

export const CHAT_CONFIG: ChatRequestsModel = {
    getQuestions: QUESTIONS + '/list',
    getAnswers: ANSWERS + '/list',
    sendMessage: ANSWERS + '/answer/create',
    sendMessageCustomer: USER + '/answer/create',
    closeTicket: QUESTIONS + '/question/close'
};
if (environment.frontEndTest) {
    CHAT_CONFIG.getQuestions = API + '/jsons/find/file?name=eboxChatQuestions';
    CHAT_CONFIG.getAnswers = API + '/jsons/find/file?name=eboxChatDialog';
    CHAT_CONFIG.sendMessage = API + '/jsons/find/file?name=eboxSendMessage';
    CHAT_CONFIG.sendMessageCustomer = API + '/jsons/find/file?name=eboxSendMessage';
    CHAT_CONFIG.closeTicket = API + '/jsons/find/file?name=success';
}

export const APP_VERSION_CONFIG = {
    version: API+'/app/version',
};

export const USER_CHAT_CONFIG: UserChatRequestsModel = {
    info: USER + '/questions',
    question: USER + '/question',
};
if (environment.frontEndTest) {
    USER_CHAT_CONFIG.info = API + '/jsons/find/file?name=eboxChatUserChatInfo';
    USER_CHAT_CONFIG.question = API + '/jsons/find/file?name=eboxChatUserChatQuestion';
}

export const PROVIDERS_CONFIG: ProvidersRequestsModel = {
    getList: USER + '/games/providers',
};
if (environment.frontEndTest) {
    PROVIDERS_CONFIG.getList = API + '/jsons/find/file?name=providersList';
}

export const SUPPORT_CONFIG: SupportModel = {
    request: USER + '/question/create',

    // request: API + '/jsons/find/file?name=success',
};
