import {Inject, Injectable, NgZone} from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class RememberUserService {

    constructor() {}

    checkSaveUser(loginControl, rememberControl) {
        if (localStorage.login && this.isUserSaved()) {
            loginControl.setValue(localStorage.login);
            rememberControl.setValue(!!this.isUserSaved());
        }
    }

    setSaveUser(login, remember) {
        localStorage.login = remember ? login : '';
        localStorage.remember_user = remember;
    }

    isUserSaved() {
        return localStorage.remember_user;
    }
}
