import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ChatAnswersAnswerModel, ChatAnswersUserModel} from "../../models/chat/chatAnswers.model";
import {USER_ROLE} from "../../config/userRole.config";
import {DATE_MASK} from "../../config/date_mask";
import {HttpHeaderResponse} from "@angular/common/http";
import {LoaderService} from "../../service/loader.service";
import {PopupComponent} from "../popup/popup.component";
import {ChatQuestionsQuestionModel} from "../../models/chat/chatQuestions.model";
import {ChatService} from "../../service/chat/chat.service";
import {ANSWER_TYPE} from "../../config/answer_type.config";
import * as panzoom from "panzoom"

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
    private _activeQuestion: Array<ChatAnswersAnswerModel>;
    @Input() userInfo: ChatAnswersUserModel;

    @Input() set activeQuestion(value: Array<ChatAnswersAnswerModel>) {
        if (this.activeQuestion && value.length && value[0].question_uuid === this.activeQuestion[0].question_uuid) {
            const newAnswersList = value ? value : [];
            newAnswersList.forEach(answer => {
                const newAnswer = this.activeQuestion.filter(oldAnswer => oldAnswer.id === answer.id);

                if (!newAnswer.length) {
                    this.activeQuestion.push(answer);
                    setTimeout(() => {
                        this.initScroll();
                    });
                }
            });
            this.initScroll();
        } else {
            this._activeQuestion = value;
            this.initScroll();
        }
    }

    get activeQuestion(): Array<ChatAnswersAnswerModel>  {
        return this._activeQuestion;
    }

    // @Input() activeQuestion: Array<ChatAnswersAnswerModel>;
    @Input() activeUserId: number;
    @Input() activeQuestionUuid: string;
    @Input() selectedQuestion: ChatQuestionsQuestionModel;
    @Input() isCustomer: boolean = false;

    USER_ROLE = USER_ROLE;
    DATE_MASK = DATE_MASK;
    ANSWER_TYPE = ANSWER_TYPE;
    fileToUpload = [];
    messageIsSending = false;

    @Output('updateDialog') updateDialog: EventEmitter<any> = new EventEmitter();

    @ViewChild('message', {static: false}) message: ElementRef;
    @ViewChild('scrolled', {static: false}) scrolled: ElementRef;
    constructor(private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private chatService: ChatService
                ) {
    }

    ngOnInit() {
    }

    handleFileInput(files: FileList) {
        const filesArray = Array.from(files);
        filesArray.forEach(newFile => {
            let unique = true;
            this.fileToUpload.forEach(existFile => {
                if (newFile.lastModified === existFile.lastModified) {
                    unique = false;
                }
            });
            if (unique) {
                this.fileToUpload.push(newFile);
            }
        });
    }
    removeFile(file) {
        const id = file.lastModified;
        this.fileToUpload = this.fileToUpload.filter(file => file.lastModified !== id);
    }

    sendMessage() {
        const message = this.message.nativeElement.value;
        const files = this.fileToUpload;
        const questionId = this.activeQuestionUuid;
        const userId = this.activeUserId;

        if ((files.length || message) && !this.messageIsSending) {
            this.messageIsSending = true;
            if (!this.isCustomer) {
                this.chatService.addFile(files, message, userId, questionId).subscribe(data => {
                    if ((<HttpHeaderResponse>data)['body'])  {
                        this.sendMessageResult(data);
                    }
                }, () => {
                    this.loaderService.hideAndShowTryAgain();
                }, () => {
                    this.loaderService.hide();
                    this.messageIsSending = false;
                });
            } else {
                this.chatService.addFileCustomer(files, message, questionId).subscribe(data => {
                    if ((<HttpHeaderResponse>data)['body'])  {
                        this.sendMessageResult(data);
                    }
                }, () => {
                    this.loaderService.hideAndShowTryAgain();
                }, () => {
                    this.loaderService.hide();
                    this.messageIsSending = false;
                });
            }

        }
    }

    sendMessageResult(data) {
        try {
            if ((<HttpHeaderResponse>data)['body'] && (<HttpHeaderResponse>data)['body'].status) {
                this.addNewMessage(data['body'].result);
            } else {
                this.popupComponent.showPopupError({text: data['body'].message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    addNewMessage(list) {
        if (this.activeQuestion) {
            const newAnswersList = list ? list : [];
            newAnswersList.forEach(answer => {
                const newAnswer = this.activeQuestion.filter(oldAnswer => oldAnswer.id === answer.id);

                if (!newAnswer.length) {
                    this.activeQuestion.push(answer);
                }
            });
        } else {
            this.activeQuestion = list;
        }

        this.updateDialog.emit();
        this.fileToUpload = [];
        this.message.nativeElement.value = "";
        setTimeout(() => {
            this.scrollToBottom();
            this.checkImagesLoaded();
        }, 500);
    }

    checkImagesLoaded() {
        const images = document.querySelectorAll('img');
        let imagesCounter = images.length;
        if (imagesCounter) {
            images.forEach((img, index) => {
                if (img.complete) {
                  imagesCounter -= 1;
                }
                img.addEventListener('load', () => {
                    imagesCounter -= 1;
                    // this.scrollToBottom();
                    if (!imagesCounter) {
                        setTimeout(() => {
                            this.scrollToBottom();
                            this.loaderService.hide();
                        });
                    }
                });
                img.addEventListener('error', () => {
                  imagesCounter -= 1;
                  // this.scrollToBottom();
                  if (!imagesCounter) {
                    setTimeout(() => {
                      this.scrollToBottom();
                      this.loaderService.hide();
                    });
                  }
                });
            });
        } else {
            setTimeout(() => {
                this.scrollToBottom();
                this.loaderService.hide();
            });
        }
    }

    scrollToBottom() {
        setTimeout(() => {
            if (this.scrolled) {
                this.scrolled.nativeElement.scrollTo({
                    top: this.scrolled.nativeElement.scrollHeight,
                    behavior: 'smooth'
                });
            }
        }, 0);

    }

    getActiveQuestionTitle() {
        return this.selectedQuestion.title;
    }

    getActiveQuestionUuid() {
        return this.selectedQuestion.uuid;
    }

    getActiveQuestionStatus() {
        return +this.selectedQuestion.status;
    }

    screenShort(e) {
        const items = (e.clipboardData || e.originalEvent.clipboardData).items;
        let blob = null;
        for (const item of items) {
            if (item.type.indexOf('image') === 0) {
                blob = item.getAsFile();
                this.fileToUpload.push(blob);
            }
        }
    }

    zoomImage(link) {
        let wrapper = document.createElement("div");
        wrapper.classList.add('zoom');
        wrapper.classList.add('is-active');
        let img = document.createElement("img");
        img.classList.add('zoom__img');
        img.src = link;
        let close = document.createElement("button");
        close.classList.add('zoom__close');
        close.addEventListener('click', (e) => {
            document.body.removeChild(wrapper);
            if (document.querySelector('.panel-wrapper')) {
              document.querySelector('.panel-wrapper').classList.remove('is-blur');
            }
            if (document.querySelector('.user-chat')) {
              document.querySelector('.user-chat').classList.remove('is-blur');
            }
        });
      close.addEventListener('touchstart', (e) => {
        document.body.removeChild(wrapper);
        if (document.querySelector('.panel-wrapper')) {
          document.querySelector('.panel-wrapper').classList.remove('is-blur');
        }
        if (document.querySelector('.user-chat')) {
          document.querySelector('.user-chat').classList.remove('is-blur');
        }
      });

        setTimeout(() => {
            let pan = panzoom.default(img, {
                autocenter: true,
            });
            img.onload = () => {
                img.style.opacity = '1';
                pan.moveTo((window.innerWidth - img.offsetWidth ) / 2, (window.innerHeight - img.offsetHeight ) / 2);
            };
            pan.on('transform', function(e) {
                const elWidth = img.offsetWidth;
                const elHeight = img.offsetHeight;
                const elScale = pan.getTransform().scale;
                const elX = pan.getTransform().x;
                const elY = pan.getTransform().y;
                const elMaxX = 1;
                const elMinX = -(elWidth * elScale - window.innerWidth);
                const elMaxY = 1;
                const elMinY = -(elHeight * elScale - window.innerHeight);

                let needChange = false;
                let elNewX = pan.getTransform().x;
                let elNewY = pan.getTransform().y;

                if (elWidth * elScale > window.innerWidth) {
                    if (elX < elMaxX) {
                        if (elX < elMinX) {
                            elNewX = elMinX;
                            needChange = true;
                        }
                    } else if (elX > elMaxX) {
                        elNewX = elMaxX;
                        needChange = true;
                    }
                } else if (elNewX !== (window.innerWidth - elWidth * elScale) / 2) {
                    elNewX = (window.innerWidth - elWidth * elScale) / 2;
                    needChange = true;
                }

                if (elHeight * elScale > window.innerHeight) {
                    if (elY < elMaxY) {
                        if (elY < elMinY) {
                            elNewY = elMinY;
                            needChange = true;
                        }
                    } else if (elY > elMaxY) {
                        elNewY = elMaxY;
                        needChange = true;
                    }
                } else if (elNewY !== (window.innerHeight - elHeight * elScale) / 2) {
                    elNewY = (window.innerHeight - elHeight * elScale) / 2;
                    needChange = true;
                }

                if (needChange) {
                    pan.moveTo(elNewX, elNewY);
                }
            });

        }, 0);

        wrapper.appendChild(img);
        wrapper.appendChild(close);
        document.body.appendChild(wrapper);
        if (document.querySelector('.panel-wrapper')) {
          document.querySelector('.panel-wrapper').classList.add('is-blur');
        }
        if (document.querySelector('.user-chat')) {
          document.querySelector('.user-chat').classList.add('is-blur');
        }
    }

    initScroll() {
        setTimeout(() => {
          if (this.activeQuestion) {
            this.updateDialog.emit();
            this.checkImagesLoaded();
          }
        });
    }
}
