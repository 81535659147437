import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ButtonComponent} from "./button/button.component";
import {PopupComponent} from "./popup/popup.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxMaskModule} from "ngx-mask";
import {LoaderComponent} from "./loader/loader.component";
import {MatSelectModule} from "@angular/material";
import {DemoMaterialModule} from "../material.module";
import {PanelHeaderComponent} from "./panel-header/panel-header.component";
import {ChatComponent} from "./chat/chat.component";
import {DialogComponent} from "./dialog/dialog.component";
import {UserInfoComponent} from "./user-info/user-info.component";

export const APP_DATE_FORMATS = {
    parse: {dateInput: {month: 'short', year: 'numeric', day: 'numeric'}},
    display: {
        dateInput: {month: 'numeric', year: 'numeric', day: 'numeric'},
        yearLabel: {year: 'numeric'},
        monthLabel: {month: 'short'}
    }
};

const SharedComponents = [
    ButtonComponent,
    PopupComponent,
    LoaderComponent,
    PanelHeaderComponent,
    ChatComponent,
    DialogComponent,
    UserInfoComponent
  ];

@NgModule({
    declarations: SharedComponents,
    imports: [
        CommonModule,
        FormsModule,
        DemoMaterialModule,
        ReactiveFormsModule,
        NgxMaskModule.forRoot(),
        MatSelectModule,
    ],
    providers: [
    ],
    exports: SharedComponents
})
export class SharedModule { }
