<div class="chat" [class.is-question-opened]="showQuestion">
    <div class="chat__subtitle">
        <button class="chat__back" (click)="goToQuestions()"></button>
        <div *ngIf="showQuestion">
            {{getActiveQuestionTitle()}}
        </div>
        <div class="chat__user-info" *ngIf="activeUser && !showQuestion && questions && questions.length > 1">
            {{activeUser.first_name}} {{activeUser.last_name}}
            <ng-container *ngIf="activeUser.phone || activeUser.email">
                ({{activeUser.phone | mask: PHONE_MASK.us}}<ng-container *ngIf="activeUser.phone && activeUser.email">, </ng-container>{{activeUser.email}})
            </ng-container>

        </div>

        <button class="chat__close-ticket"
                *ngIf="showQuestion && activeQuestion" [disabled]="questions && getActiveQuestionStatus()"
                (click)="closeTicket()">
            <ng-container *ngIf="!getActiveQuestionStatus()">Close ticket</ng-container>
            <ng-container *ngIf="getActiveQuestionStatus()">Ticket closed</ng-container>
        </button>
    </div>
    <ul class="questions-list" *ngIf="!showQuestion">
        <li class="questions-list__item" *ngFor="let question of questions">
            <button class="questions-list__btn" (click)="showUserQuestionDialog(question)">
                <span class="questions-list__title">{{question.title}}</span>
                <span class="questions-list__game">{{question.game_provider_name | uppercase}}</span>
                <span class="questions-list__date">{{question.date | date: DATE_MASK.withoutTime | lowercase}}</span>
                <span class="questions-list__status" [class.is-closed]="question.status === QUESTION_TYPE.closed">{{getQuestionStatus(question.status)}}</span>
            </button>
        </li>
    </ul>

    <ng-container *ngIf="showQuestion">
        <app-dialog [activeQuestion]="activeQuestion"
                    [activeUserId]="activeUserId"
                    [userInfo]="userInfo"
                    [activeQuestionUuid]="activeQuestionUuid"
                    [selectedQuestion]="selectedQuestion"></app-dialog>
    </ng-container>
</div>

