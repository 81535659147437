export const RouterPaths = {
  panel: {
    main: '/home/panel',
  },
  userChat: {
    main: '/auth',
  },
  newTicket: {
    main: '/new-ticket',
  }
};
