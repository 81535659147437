<header class="panel-header">
    <div class="panel-header__col">
        <svg class="panel-header__logo-icon">
            <use xlink:href="../../../../assets/images/sprite.svg#chat"></use>
        </svg>
        <button class="panel-header__menu panel-header__menu--mobile" (click)="toggleLeftMenu()">
            <svg>
                <use xlink:href="../../../../assets/images/sprite.svg#menu"></use>
            </svg>
        </button>
        <button class="panel-header__refresh" (click)="refreshPage()">
            <svg>
                <use xlink:href="../../../../assets/images/sprite.svg#refresh"></use>
            </svg>
        </button>
    </div>

    <div class="panel-header__col">
<!--        <div class="panel-header__user">{{userName}}</div>-->
<!--        <div class="panel-header__menu">-->
<!--            <div class="panel-menu" [class.is-opened]="openMenu">-->
<!--                <button class="panel-menu__menu-btn" (click)="toggleMenu()">-->
<!--                    <svg class="panel-menu__dots-icon" width="34" height="34">-->
<!--                        <use xlink:href="../../../../assets/images/sprite.svg#menu-dots"></use>-->
<!--                    </svg>-->
<!--                </button>-->
<!--                <div class="panel-menu__inner">-->
<!--                    <button class="panel-menu__btn" (click)="logout()">-->
<!--                        Log Out-->
<!--                    </button>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <button class="panel-header__logout-btn"
                *ngIf="!hideLogOut"
                (click)="logout()">
            Log Out
        </button>
    </div>
</header>
