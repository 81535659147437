import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "../shared/popup/popup.component";
var RefreshService = /** @class */ (function () {
    function RefreshService(popupComponent) {
        this.popupComponent = popupComponent;
        this.emitRefresh = new EventEmitter(null);
        this.refreshAfterMin = 5;
        this.refreshAfterSec = this.refreshAfterMin * 60;
    }
    RefreshService.prototype.setRefreshTimer = function () {
        var _this = this;
        // console.log('set refresh');
        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
        }
        this.refreshTimeout = setTimeout(function () {
            _this.refresh();
        }, this.refreshAfterSec * 1000);
    };
    RefreshService.prototype.refresh = function () {
        // console.log('refresh');
        this.emitRefresh.emit(Math.round(+new Date() / 1000) + Math.random());
        this.setRefreshTimer();
    };
    RefreshService.prototype.stopRefresh = function () {
        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
        }
    };
    RefreshService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RefreshService_Factory() { return new RefreshService(i0.ɵɵinject(i1.PopupComponent)); }, token: RefreshService, providedIn: "root" });
    return RefreshService;
}());
export { RefreshService };
