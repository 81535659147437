import {EventEmitter, Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {UserIdleService} from '../user-idle/user-idle.service';
import {Router} from '@angular/router';
import {AuthConfig} from './auth-config';
import {AUTH_CONFIG} from '../../../config/api';
import {BehaviorSubject, Subject} from 'rxjs';
import {LoaderService} from "../../../service/loader.service";
import {RefreshService} from "../../../service/refresh.service";
import {LoginResponseModel} from "../../../models/login/loginResponse.model";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private config = AUTH_CONFIG;

    private storage = sessionStorage;
    storageKeys = {
        currentUser: 'currentUser',
        sessionId: 'sessionId'
    };

    roles: string[];
    rolesObservable = new EventEmitter<any>();
    userLoggedIn = new EventEmitter<any>();
    private authStamp;

    constructor(@Inject(AuthConfig) private authConfig: AuthConfig,
                private http: HttpClient,
                private refreshService: RefreshService,
                private loaderService: LoaderService,
                private userIdle: UserIdleService,
                private router: Router) {
    }

    isUserLogged() {
        return !!this.storage.getItem(this.storageKeys.currentUser);
    }

    login(login: string, password: string) {
        this.loaderService.show();
        return this.http.post<LoginResponseModel>(this.config.login, {login, password})
            .pipe(map((res: LoginResponseModel) => {
                if (res.status) {
                    this.loginUser(res);
                }

                return res;
            }));
    }

    loginUser(res) {
        this.setData(res);
        this.userLoggedIn.emit(true);
    }

    setData(result: LoginResponseModel){
        this.storage = sessionStorage;
        this.storage.setItem(this.storageKeys.currentUser, JSON.stringify({
            token: result.token,
            firstName: result.firstName,
            lastName: result.lastName
        }));
    }

    async logout() {
        this.refreshService.stopRefresh();
        this.storage.removeItem(this.storageKeys.currentUser);

        await this.checkSession();
        this.userLoggedIn.emit(false);

    }

    setStamp(){
        var now = Date.now();

        this.authStamp = now;

        let $this = this;
        setTimeout(function(){
            $this.setStamp();
        },1000);
    }

    checkStamp(){
        if (this.storage.getItem(this.storageKeys.currentUser) && !this.storage.getItem(this.storageKeys.sessionId)) {
            var now = Date.now();

            if(this.authStamp){
                if(now > (this.authStamp+3000000)){
                    this.logout();

                    this.router.navigate(['/']);

                    return;
                }else{
                    this.refreshTokenNow();
                }
            }else{
                this.refreshTokenNow();
            }
        }
    }

    refreshTokenNow(){
        if (this.storage.getItem(this.storageKeys.currentUser) && !this.storage.getItem(this.storageKeys.sessionId)) {
            this.http.post<any>(this.config.refreshToken, {}).subscribe(res => {
                if (res.status) {
                    this.setData(res.result);
                }else{
                    this.router.navigate(['/']);
                }

                return res;
            });
        }

        return false;
    }

    refreshToken() {
        let $this = this;
        setTimeout(function(){
            $this.refreshToken();
        },60*10*1000);

        this.refreshTokenNow();
    }

    async checkSession() {

        // this.storage = sessionStorage;
        // if (this.storage.getItem('currentUser')) {
        //
        //     this.refreshToken();
        // } else {
        //     this.router.navigate([this.authConfig.loginRoute],
        //         {
        //             queryParams: this.authConfig.loginRoute !== location.pathname ?
        //                 {
        //                     returnUrl:
        //                     location.pathname
        //                 } : null
        //         });
        //
        // }
    }

    getCurrentUser(){
        if (sessionStorage.getItem(this.storageKeys.currentUser)) {
            try {
                const currentUser = JSON.parse(sessionStorage.getItem(this.storageKeys.currentUser));

                return currentUser;
            }catch (e) {

            }
        }

        return false;
    }

    getUserFirstName() {
        if (this.getCurrentUser()) {
            try {
                const currentUser = this.getCurrentUser();

                return currentUser.firstName;
            }catch (e) {

            }

        }

        return '';
    }

    getUserLastName() {
        if (this.getCurrentUser()) {
            try {
                const currentUser = this.getCurrentUser();

                return currentUser.lastName;
            }catch (e) {

            }

        }

        return '';
    }

    getUserFullName() {
        return `${this.getUserFirstName()} ${this.getUserLastName()}`;
    }

    getToken() {
        if (this.getCurrentUser()) {
            try {
                const currentUser = this.getCurrentUser();

                return currentUser.token;
            }catch (e) {

            }

        }

        return '';
    }

    setSessionId(sessionId: string): void {
        if (sessionId) {
            this.storage.setItem(this.storageKeys.sessionId, sessionId);
        } else {
            this.storage.removeItem(this.storageKeys.sessionId);
        }
    }

    getSessionId(): string | boolean {
        if (this.storage.getItem(this.storageKeys.sessionId)) {
            return this.storage.getItem(this.storageKeys.sessionId);
        }
        return false;
    }

    setIframeVision() {
        localStorage.setItem('hideLogout', '1');
    }

    isIframeVision() {
        return localStorage.getItem('hideLogout') === '1';
    }
}
