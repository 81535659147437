<div class="user-chat" #scrolled>
    <div class="gl-title">Tickets</div>
    <div class="gl-subtitle">Below is the history of all submitted tickets. You can always submit a new on. We value your business and will respond within 24 hours.</div>
    <div class="user-chat__button-wrapper">
        <a [routerLink]="getNewTicketLink()" class="user-chat__button button button--client button--white-text">
            New Ticket
        </a>
    </div>


    <div class="content-block">
        <div class="content-block__title gl-page-title c-accent">Support Record</div>

        <div class="user-questions-list">
            <table>
                <thead>
                    <tr>
                        <th class="user-questions-list__date">DATE</th>
                        <th class="user-questions-list__provider">System</th>
                        <th class="user-questions-list__description">Description</th>
                        <th class="user-questions-list__status">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let question of questionsList"
                        [class.is-opened]="selectedQuestion && (question.uuid === selectedQuestion.uuid)"
                        [class.is-closed]="question.status === QUESTION_TYPE.closed"
                        (click)="openQuestion(question)">
                        <td class="user-questions-list__date">{{question.created | date: DATE_MASK.shortYear}}</td>
                        <td class="user-questions-list__provider ellipsis-on-overflow">{{question.game_provider_name}}</td>
                        <td class="user-questions-list__description ellipsis-on-overflow">{{question.title}}</td>
                        <td class="user-questions-list__status">{{getQuestionStatus(question.status)}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="content-block" *ngIf="showQuestion">
        <div class="content-block__title gl-page-title c-accent">Ticket summary</div>

        <div class="user-info-list">
            <table>
                <tbody>
                    <tr>
                        <td class="user-info-list__name">Name</td>
                        <td class="user-info-list__value">{{questionInfo.firstName}} {{questionInfo.lastName}}</td>
                    </tr>
                    <tr>
                        <td class="user-info-list__name">Phone</td>
                        <td class="user-info-list__value">{{questionInfo.phone | mask: phoneMask}}</td>
                    </tr>
                    <tr>
                        <td class="user-info-list__name">Email</td>
                        <td class="user-info-list__value">{{questionInfo.email}}</td>
                    </tr>
                    <tr>
                        <td class="user-info-list__name">Time</td>
                        <td class="user-info-list__value">{{questionInfo.time | date: DATE_MASK.onlyHours}}</td>
                    </tr>
                    <tr>
                        <td class="user-info-list__name">Description</td>
                        <td class="user-info-list__value">{{questionInfo.description}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="content-block" *ngIf="showQuestion">
        <div class="content-block__title gl-page-title c-accent">Support Chat</div>
        <div class="content-block__no-padding">
            <app-dialog [selectedQuestion]="selectedQuestion"
                        [activeQuestionUuid]="activeQuestionUuid"
                        [activeQuestion]="activeQuestion"
                        [isCustomer]="true"
                        [activeUserId]="null"
                        (updateDialog)="updateDialog()"></app-dialog>
        </div>

    </div>
</div>

