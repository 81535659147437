import * as i0 from "@angular/core";
var AppPromptService = /** @class */ (function () {
    function AppPromptService() {
    }
    Object.defineProperty(AppPromptService.prototype, "deferredPrompt", {
        get: function () {
            return this._deferredPrompt;
        },
        set: function (value) {
            this._deferredPrompt = value;
        },
        enumerable: true,
        configurable: true
    });
    AppPromptService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppPromptService_Factory() { return new AppPromptService(); }, token: AppPromptService, providedIn: "root" });
    return AppPromptService;
}());
export { AppPromptService };
