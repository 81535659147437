<div class="user-info">
    <table class="user-info__inner">
        <tbody>
        <tr>
            <td>Name</td>
            <td>{{userInfo.name}}</td>
        </tr>
        <tr>
            <td>Phone</td>
            <td>{{userInfo.phone | mask: PHONE_MASK.us}}</td>
        </tr>
        <tr>
            <td>Email</td>
            <td>{{userInfo.email}}</td>
        </tr>
        <tr>
            <td>Game</td>
            <td>{{userInfo.game}}</td>
        </tr>
        <tr>
            <td>Game Code</td>
            <td>{{userInfo.game_code}}</td>
        </tr>
        <tr>
            <td>User ID</td>
            <td>{{userInfo.user_id}}</td>
        </tr>
        </tbody>
    </table>
</div>

