import { OnInit } from '@angular/core';
import { Validators } from "@angular/forms";
import { RouterPaths } from "../../config/router-paths.model";
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "../../modules/auth/_services/authentication.service";
import * as i3 from "@angular/router";
import * as i4 from "../../service/app/appPrompt.service";
import * as i5 from "../../service/loader.service";
import * as i6 from "../../service/rememberUser.service";
import * as i7 from "../../shared/popup/popup.component";
var LoginComponent = /** @class */ (function () {
    function LoginComponent(formBuilder, authenticationService, router, appPromptService, loaderService, rememberUserService, activatedRoute, popupComponent) {
        this.formBuilder = formBuilder;
        this.authenticationService = authenticationService;
        this.router = router;
        this.appPromptService = appPromptService;
        this.loaderService = loaderService;
        this.rememberUserService = rememberUserService;
        this.activatedRoute = activatedRoute;
        this.popupComponent = popupComponent;
        this.formKeys = {
            login: 'login',
            password: 'password',
            remember_user: 'remember_user',
        };
    }
    LoginComponent.prototype.ngOnInit = function () {
        localStorage.removeItem('hideLogout');
        this.initForm();
    };
    LoginComponent.prototype.setForm = function () {
        var _a;
        this.userForm = this.formBuilder.group((_a = {},
            _a[this.formKeys.login] = [null, Validators.required],
            _a[this.formKeys.password] = [null, Validators.required],
            _a[this.formKeys.remember_user] = [false],
            _a));
        return this.userForm;
    };
    LoginComponent.prototype.initForm = function () {
        var form = this.setForm();
        var loginControl = form.controls[this.formKeys.login];
        var rememberControl = form.controls[this.formKeys.remember_user];
        this.rememberUserService.checkSaveUser(loginControl, rememberControl);
    };
    LoginComponent.prototype.onUserFormSubmit = function () {
        if (!this.isFormValid()) {
            return;
        }
        var login = this.userForm.controls[this.formKeys.login].value;
        var password = this.userForm.controls[this.formKeys.password].value;
        if (login && password) {
            this.sendLoginRequest(login, password);
        }
        else {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    LoginComponent.prototype.sendLoginRequest = function (login, password) {
        var _this = this;
        this.authenticationService.login(login, password).subscribe(function (data) {
            _this.checkLoginSubmit(data);
        }, function (error) {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    LoginComponent.prototype.checkLoginSubmit = function (data) {
        try {
            if (data.status) {
                this.rememberUser();
                this.goToHomePage();
            }
            else {
                this.popupComponent.showPopupError({ text: data.message });
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    LoginComponent.prototype.rememberUser = function () {
        var login = this.userForm.controls.login.value;
        var remember = this.userForm.controls.remember_user.value;
        this.rememberUserService.setSaveUser(login, remember);
    };
    LoginComponent.prototype.onRememberUser = function (e) {
        var checked = e.target.checked;
        this.userForm.controls.remember_user.setValue(!!checked);
    };
    LoginComponent.prototype.goToHomePage = function () {
        this.router.navigate([RouterPaths.panel.main]);
    };
    LoginComponent.prototype.isFormValid = function () {
        if (this.userForm) {
            return this.userForm.valid;
        }
        return false;
    };
    LoginComponent.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginComponent_Factory() { return new LoginComponent(i0.ɵɵinject(i1.FormBuilder), i0.ɵɵinject(i2.AuthenticationService), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.AppPromptService), i0.ɵɵinject(i5.LoaderService), i0.ɵɵinject(i6.RememberUserService), i0.ɵɵinject(i3.ActivatedRoute), i0.ɵɵinject(i7.PopupComponent)); }, token: LoginComponent, providedIn: "root" });
    return LoginComponent;
}());
export { LoginComponent };
