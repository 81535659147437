import {
    Component,
    OnInit,
    Injectable
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {ActivatedRoute, Router} from "@angular/router";
import {PopupComponent} from "../../shared/popup/popup.component";
import {slideToRight} from "../../animations/router.animations";
import {LoaderService} from "../../service/loader.service";
import {RememberUserService} from "../../service/rememberUser.service";
import {AppPromptService} from "../../service/app/appPrompt.service";
import {RouterPaths} from "../../config/router-paths.model";
import {LoginResponseModel} from "../../models/login/loginResponse.model";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    animations: [slideToRight],
    host: {'[@slideToRight]': ''}
})
@Injectable({providedIn: 'root'})
export class LoginComponent implements OnInit {
    userForm: FormGroup;
    formKeys = {
        login: 'login',
        password: 'password',
        remember_user: 'remember_user',
    };

    constructor(
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private router: Router,
        private appPromptService: AppPromptService,
        private loaderService: LoaderService,
        private rememberUserService: RememberUserService,
        private activatedRoute: ActivatedRoute,
        private popupComponent: PopupComponent) {
    }
    ngOnInit() {
        localStorage.removeItem('hideLogout');
        this.initForm();
    }

    setForm(): FormGroup {
        this.userForm = this.formBuilder.group({
            [this.formKeys.login]: [null, Validators.required],
            [this.formKeys.password]: [null, Validators.required],
            [this.formKeys.remember_user]: [false]
        });
        return this.userForm;
    }

    initForm(): void {
        const form = this.setForm();

        const loginControl = form.controls[this.formKeys.login];
        const rememberControl = form.controls[this.formKeys.remember_user];
        this.rememberUserService.checkSaveUser(loginControl, rememberControl);
    }

    onUserFormSubmit(){
        if (!this.isFormValid()) {
            return;
        }

        const login = this.userForm.controls[this.formKeys.login].value;
        const password = this.userForm.controls[this.formKeys.password].value;

        if (login && password) {
            this.sendLoginRequest(login, password);
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    sendLoginRequest(login: string, password: string) {
        this.authenticationService.login(login, password).subscribe(data => {
            this.checkLoginSubmit(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    checkLoginSubmit(data: LoginResponseModel) {
        try {
            if (data.status) {
                this.rememberUser();
                this.goToHomePage();
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    rememberUser() {
        const login = this.userForm.controls.login.value;
        const remember = this.userForm.controls.remember_user.value;
        this.rememberUserService.setSaveUser(login, remember);
    }

    onRememberUser(e) {
        const checked = e.target.checked;
        this.userForm.controls.remember_user.setValue(!!checked);
    }

    goToHomePage() {
        this.router.navigate([RouterPaths.panel.main]);
    }

    isFormValid() {
        if (this.userForm) {
            return this.userForm.valid;
        }
        return false;
    }
}
