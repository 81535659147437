import {Inject, Injectable, NgZone} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AppPromptService {
    private _deferredPrompt;

    constructor() {

    }


    get deferredPrompt() {
        return this._deferredPrompt;
    }

    set deferredPrompt(value) {
        this._deferredPrompt = value;
    }
}
