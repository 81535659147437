import { CHAT_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var ChatService = /** @class */ (function () {
    function ChatService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        this.config = CHAT_CONFIG;
    }
    ChatService.prototype.getQuestions = function (userId) {
        // this.loaderService.show();
        return this.http.post(this.config.getQuestions, { userId: userId });
    };
    ChatService.prototype.getDialog = function (uuid) {
        // this.loaderService.show();
        return this.http.post(this.config.getAnswers, { uuid: uuid });
    };
    ChatService.prototype.closeTicket = function (uuid) {
        this.loaderService.show();
        return this.http.post(this.config.closeTicket, { uuid: uuid });
    };
    ChatService.prototype.addFile = function (files, message, userId, questionId) {
        // this.loaderService.show();
        var formData = new FormData();
        for (var i = 0; i < files.length; i++) {
            formData.append('files[]', files[i]);
        }
        formData.append('message', message);
        formData.append('userId', userId);
        formData.append('questionId', questionId);
        return this.http.post(this.config.sendMessage, formData, {
            reportProgress: true,
            observe: 'events'
        });
    };
    ChatService.prototype.addFileCustomer = function (files, message, questionId) {
        // this.loaderService.show();
        var formData = new FormData();
        for (var i = 0; i < files.length; i++) {
            formData.append('files[]', files[i]);
        }
        formData.append('message', message);
        formData.append('uuid', questionId);
        return this.http.post(this.config.sendMessageCustomer, formData, {
            reportProgress: true,
            observe: 'events'
        });
    };
    ChatService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChatService_Factory() { return new ChatService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: ChatService, providedIn: "root" });
    return ChatService;
}());
export { ChatService };
