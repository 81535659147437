import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var MobileMenuService = /** @class */ (function () {
    function MobileMenuService() {
        this.visible = new BehaviorSubject(true);
    }
    MobileMenuService.prototype.show = function () {
        this.visible.next(true);
    };
    MobileMenuService.prototype.hide = function () {
        this.visible.next(false);
    };
    MobileMenuService.prototype.toggle = function () {
        this.visible.next(!this.visible.value);
    };
    MobileMenuService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MobileMenuService_Factory() { return new MobileMenuService(); }, token: MobileMenuService, providedIn: "root" });
    return MobileMenuService;
}());
export { MobileMenuService };
