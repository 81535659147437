import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {MainComponent} from './main/main.component';
import {LoginComponent} from "./login/login.component";
import {AuthGuard} from '../modules/auth/_guards/auth.guard';
import {PanelComponent} from "./panel/panel.component";
import {UserChatComponent} from "./user-chat/user-chat.component";
import {NewTicketComponent} from "./new-ticket/new-ticket.component";

const routes: Routes = [
    {
        path: '', component: MainComponent, children: [
            {path: '', component: LoginComponent},
            {path: 'login', component: LoginComponent},
            {path: 'auth/:id', component: UserChatComponent},
            {path: 'new-ticket', component: NewTicketComponent},
            {path: 'home', component: HomeComponent, canActivate: [AuthGuard], children: [
            // {path: 'home', component: HomeComponent, children: [
                    {path: 'panel', component: PanelComponent},
                ]},
            {path: '**', redirectTo: 'login', pathMatch: 'full'}
        ]
    }

];



@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesModule {
}
