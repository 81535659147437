/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chat.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../dialog/dialog.component.ngfactory";
import * as i4 from "../dialog/dialog.component";
import * as i5 from "../../service/loader.service";
import * as i6 from "../popup/popup.component";
import * as i7 from "../../service/chat/chat.service";
import * as i8 from "ngx-mask";
import * as i9 from "./chat.component";
import * as i10 from "../../service/refresh.service";
var styles_ChatComponent = [i0.styles];
var RenderType_ChatComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChatComponent, data: {} });
export { RenderType_ChatComponent as RenderType_ChatComponent };
function View_ChatComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getActiveQuestionTitle(); _ck(_v, 1, 0, currVal_0); }); }
function View_ChatComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [", "]))], null, null); }
function View_ChatComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" (", ""])), i1.ɵppd(2, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(5, null, ["", ") "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.activeUser.phone && _co.activeUser.email); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _co.activeUser.phone, _co.PHONE_MASK.us)); _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.activeUser.email; _ck(_v, 5, 0, currVal_2); }); }
function View_ChatComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "chat__user-info"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.activeUser.phone || _co.activeUser.email); _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeUser.first_name; var currVal_1 = _co.activeUser.last_name; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_ChatComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Close ticket"]))], null, null); }
function View_ChatComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Ticket closed"]))], null, null); }
function View_ChatComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "chat__close-ticket"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeTicket() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatComponent_6)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatComponent_7)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.getActiveQuestionStatus(); _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.getActiveQuestionStatus(); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.questions && _co.getActiveQuestionStatus()); _ck(_v, 0, 0, currVal_0); }); }
function View_ChatComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "li", [["class", "questions-list__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "button", [["class", "questions-list__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showUserQuestionDialog(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "questions-list__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["class", "questions-list__game"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵppd(6, 1), (_l()(), i1.ɵeld(7, 0, null, null, 3, "span", [["class", "questions-list__date"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵppd(9, 2), i1.ɵppd(10, 1), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "questions-list__status"]], [[2, "is-closed", null]], null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.title; _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent, 1), _v.context.$implicit.game_provider_name)); _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 8, 0, _ck(_v, 10, 0, i1.ɵnov(_v.parent.parent, 2), i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v.parent.parent, 3), _v.context.$implicit.date, _co.DATE_MASK.withoutTime)))); _ck(_v, 8, 0, currVal_2); var currVal_3 = (_v.context.$implicit.status === _co.QUESTION_TYPE.closed); _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.getQuestionStatus(_v.context.$implicit.status); _ck(_v, 12, 0, currVal_4); }); }
function View_ChatComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "questions-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatComponent_9)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.questions; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ChatComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-dialog", [], null, null, null, i3.View_DialogComponent_0, i3.RenderType_DialogComponent)), i1.ɵdid(2, 114688, null, 0, i4.DialogComponent, [i5.LoaderService, i6.PopupComponent, i7.ChatService], { userInfo: [0, "userInfo"], activeQuestion: [1, "activeQuestion"], activeUserId: [2, "activeUserId"], activeQuestionUuid: [3, "activeQuestionUuid"], selectedQuestion: [4, "selectedQuestion"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userInfo; var currVal_1 = _co.activeQuestion; var currVal_2 = _co.activeUserId; var currVal_3 = _co.activeQuestionUuid; var currVal_4 = _co.selectedQuestion; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_ChatComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i8.MaskPipe, [i8.MaskApplierService]), i1.ɵpid(0, i2.UpperCasePipe, []), i1.ɵpid(0, i2.LowerCasePipe, []), i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(4, 0, null, null, 12, "div", [["class", "chat"]], [[2, "is-question-opened", null]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "chat__subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "button", [["class", "chat__back"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToQuestions() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatComponent_1)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatComponent_2)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatComponent_5)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatComponent_8)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatComponent_10)), i1.ɵdid(16, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showQuestion; _ck(_v, 8, 0, currVal_1); var currVal_2 = (((_co.activeUser && !_co.showQuestion) && _co.questions) && (_co.questions.length > 1)); _ck(_v, 10, 0, currVal_2); var currVal_3 = (_co.showQuestion && _co.activeQuestion); _ck(_v, 12, 0, currVal_3); var currVal_4 = !_co.showQuestion; _ck(_v, 14, 0, currVal_4); var currVal_5 = _co.showQuestion; _ck(_v, 16, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showQuestion; _ck(_v, 4, 0, currVal_0); }); }
export function View_ChatComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chat", [], null, null, null, View_ChatComponent_0, RenderType_ChatComponent)), i1.ɵdid(1, 245760, null, 0, i9.ChatComponent, [i7.ChatService, i10.RefreshService, i6.PopupComponent, i5.LoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChatComponentNgFactory = i1.ɵccf("app-chat", i9.ChatComponent, View_ChatComponent_Host_0, { activeUserId: "activeUserId", activeUser: "activeUser" }, { emitRefresh: "onRefresh" }, []);
export { ChatComponentNgFactory as ChatComponentNgFactory };
