import {EventEmitter, Injectable} from '@angular/core';
import {PopupComponent} from "../shared/popup/popup.component";

@Injectable({
    providedIn: 'root'
})
export class RefreshService {
    public emitRefresh = new EventEmitter(null);

    refreshTimeout;
    refreshAfterMin = 5;
    refreshAfterSec = this.refreshAfterMin * 60;

    constructor(private popupComponent: PopupComponent) {

    }

    setRefreshTimer() {
        // console.log('set refresh');
        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
        }
        this.refreshTimeout = setTimeout(() => {
            this.refresh();
        }, this.refreshAfterSec * 1000);
    }

    refresh() {
        // console.log('refresh');
        this.emitRefresh.emit(Math.round(+new Date()/1000)+Math.random());
        this.setRefreshTimer();
    }

    stopRefresh() {
        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
        }
    }

}
