import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {CHAT_CONFIG} from "../../config/api";
import {LoaderService} from "../loader.service";

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private config = CHAT_CONFIG;

  constructor(private http: HttpClient,
              public loaderService: LoaderService) {}

  getQuestions(userId) {
    // this.loaderService.show();
    return this.http.post<any>(this.config.getQuestions, {userId});
  }

  getDialog(uuid) {
    // this.loaderService.show();
    return this.http.post<any>(this.config.getAnswers, {uuid});
  }

  closeTicket(uuid) {
    this.loaderService.show();
    return this.http.post<any>(this.config.closeTicket, {uuid});
  }

  addFile(files, message, userId, questionId) {
    // this.loaderService.show();

    const formData: FormData = new FormData();
    for (let i=0; i<files.length; i++){
      formData.append('files[]', files[i]);
    }

    formData.append('message', message);
    formData.append('userId', userId);
    formData.append('questionId', questionId);

    return this.http.post(this.config.sendMessage, formData, {
      reportProgress: true,
      observe: 'events'
    })
  }

  addFileCustomer(files, message, questionId) {
    // this.loaderService.show();

    const formData: FormData = new FormData();
    for (let i=0; i<files.length; i++){
      formData.append('files[]', files[i]);
    }

    formData.append('message', message);
    formData.append('uuid', questionId);

    return this.http.post(this.config.sendMessageCustomer, formData, {
      reportProgress: true,
      observe: 'events'
    })
  }
}


