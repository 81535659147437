import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {ChatService} from "../../service/chat/chat.service";
import {LoaderService} from "../../service/loader.service";
import {PopupComponent} from "../popup/popup.component";
import {HttpHeaderResponse} from "@angular/common/http";
import {USER_ROLE} from "../../config/userRole.config";
import {ChatQuestionsQuestionModel} from "../../models/chat/chatQuestions.model";
import {PanelInfoDataUserModel} from "../../models/panel-info/panelInfoData.model";
import {DATE_MASK} from "../../config/date_mask";
import {ChatAnswersAnswerModel, ChatAnswersModel, ChatAnswersUserModel} from "../../models/chat/chatAnswers.model";
import {RefreshService} from "../../service/refresh.service";
import {PHONE_MASK} from "../../config/country_phone_mask";
import {QUESTION_STATUS, QUESTION_TYPE} from "../../config/question_type.config";

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss']
})

export class ChatComponent implements OnInit {
    private _activeUserId: number;

    @Input() set activeUserId(value: number) {
        this._activeUserId = value;
        this.activeQuestion = null;
        this.questions = null;

        if (value) {
            this.showQuestion = false;
            this.getQuestions(this.activeUserId);
            this.refreshService.setRefreshTimer();
        }
    }

    get activeUserId() : number  {
        return this._activeUserId;
    }
    questions: Array<ChatQuestionsQuestionModel>;
    selectedQuestion: ChatQuestionsQuestionModel;


    @Input() activeUser: PanelInfoDataUserModel;
    @Output('onRefresh') emitRefresh: EventEmitter<any> = new EventEmitter();

    DATE_MASK = DATE_MASK;
    PHONE_MASK = PHONE_MASK;
    QUESTION_TYPE = QUESTION_TYPE;

    activeQuestion: Array<ChatAnswersAnswerModel>;
    showQuestion = false;
    activeQuestionUuid;
    ticketClosed = false;
    refreshSubscription;
    userInfo: ChatAnswersUserModel;
    constructor(private chatService: ChatService,
                private refreshService: RefreshService,
                private popupComponent: PopupComponent,
                private loaderService: LoaderService) {
    }


    ngOnInit() {
        this.refreshSubscription = this.refreshService.emitRefresh.subscribe(val => {
            if (val !== null && this.activeUserId) {
                if (this.showQuestion) {
                    this.getDialog(this.getActiveQuestionUuid(), false)
                } else {
                    this.getQuestions(this.activeUserId, false);
                }
            }
        });
    }

    getQuestions(userId, loader = true) {
        if (loader) {
            this.loaderService.show();
        }
        this.chatService.getQuestions(userId).subscribe(data => {
            this.setQuestions(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    setQuestions(data) {
        try {
            if (data.status) {
                this.ticketClosed = false;
                this.questions = data.result;
                if (data.result.length === 1) {
                    this.showUserQuestionDialog(data.result[0]);
                }
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    getDialog(uuid, loader = true) {
        if (loader) {
            this.loaderService.show();
        }
        this.chatService.getDialog(uuid).subscribe(data => {
            this.setDialog(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            // this.loaderService.hide();
        });
    }

    setDialog(data: ChatAnswersModel) {
        try {
            if (data.status) {
                this.activeQuestion = data.result ? data.result : [];
                this.userInfo = data.user ? data.user : null;
            }
            this.loaderService.hide();
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
        this.showQuestion = true;
    }

    closeTicket() {
        const uuid = this.getActiveQuestionUuid();
        this.chatService.closeTicket(uuid).subscribe(data => {
            this.closeTicketResult(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    closeTicketResult(data) {
        try {
            if (data.status) {
                this.ticketClosed = true;
                this.emitRefresh.emit();
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    showUserQuestionDialog(question) {
        // this.showQuestion = true;
        this.selectedQuestion = question;
        this.activeQuestionUuid = question.uuid;
        this.getDialog(this.activeQuestionUuid);
        this.refreshService.setRefreshTimer();
    }

    ngOnDestroy() {
        this.refreshSubscription.unsubscribe();
    }

    goToQuestions() {
        this.activeQuestion = null;
        this.showQuestion = false;
        this.getQuestions(this.activeUserId, false);
    }

    getActiveQuestionTitle() {
        return this.selectedQuestion.title;
    }

    getActiveQuestionUuid() {
        return this.selectedQuestion.uuid;
    }

    getActiveQuestionStatus() {
        return +this.selectedQuestion.status || this.ticketClosed;
    }

    getQuestionStatus(status) {
        return QUESTION_STATUS[status];
    }
}
