<div class="panel-wrapper">
    <app-panel-header (refresh)="refreshPage()" [userName]="getUserName()"></app-panel-header>

    <div class="panel">
        <div class="panel__left" [class.is-visible]="showMobileMenu">
            <div class="panel__block" *ngIf="usersNew.length">
                <div class="panel__subtitle">
                    Open
                </div>
                <ul class="panel__users-list users-list">
                    <li class="users-list__item" [class.is-active]="user.user_id === activeUserId" *ngFor="let user of usersNew">
                        <button class="users-list__btn" (click)="showUserQuestions(user)">{{user.first_name}} {{user.last_name}}</button>
                    </li>
                </ul>
            </div>
            <div class="panel__block"
                 [class.panel__block--separator]="usersNew.length"
                 *ngIf="usersOld.length">
                <div class="panel__subtitle">
                    Closed
                </div>
                <ul class="panel__users-list users-list">
                    <li class="users-list__item" [class.is-active]="user.user_id === activeUserId" *ngFor="let user of usersOld">
                        <button class="users-list__btn" (click)="showUserQuestions(user)">{{user.first_name}} {{user.last_name}}</button>
                    </li>
                </ul>
            </div>
        </div>
        <div class="panel__right">
           <app-chat (onRefresh)="refreshPage()" [activeUserId]="activeUserId" [activeUser]="activeUser"></app-chat>
        </div>
    </div>
</div>

