<div class="login">
    <div class="login__inner">
        <div class="login__header">
            <svg class="login__header-icon" width="34" height="34">
                <use xlink:href="../../../../assets/images/sprite.svg#chat"></use>
            </svg>
        </div>

        <div class="login__forms">
            <div class="login__tab">
                <form [formGroup]="userForm" (ngSubmit)="onUserFormSubmit()" class="login__form">
                    <div class="login__item">
                        <input formControlName="login"
                               placeholder="Login"
                               [type]="'text'"
                               class="input"
                               autocomplete="off">
                    </div>

                    <div class="login__item">
                        <input formControlName="password"
                               placeholder="Password"
                               [type]="'password'"
                               class="input"
                               autocomplete="off">
                    </div>

                    <div class="login__item t-small">
                        <input formControlName="remember_user"
                               id="remember_user"
                               [type]="'checkbox'"
                               class="checkbox"
                               (change)="onRememberUser($event)"
                               [checked]="!!userForm.controls.remember_user.value"
                               autocomplete="off">
                        <label for="remember_user">Remember Username</label>
                    </div>
                    <div class="login__btns">
                        <button class="button" [disabled]="!userForm.valid">
                            Login
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
