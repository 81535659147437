import { EventEmitter, OnInit } from '@angular/core';
import { DATE_MASK } from "../../config/date_mask";
import { PHONE_MASK } from "../../config/country_phone_mask";
import { QUESTION_STATUS, QUESTION_TYPE } from "../../config/question_type.config";
var ChatComponent = /** @class */ (function () {
    function ChatComponent(chatService, refreshService, popupComponent, loaderService) {
        this.chatService = chatService;
        this.refreshService = refreshService;
        this.popupComponent = popupComponent;
        this.loaderService = loaderService;
        this.emitRefresh = new EventEmitter();
        this.DATE_MASK = DATE_MASK;
        this.PHONE_MASK = PHONE_MASK;
        this.QUESTION_TYPE = QUESTION_TYPE;
        this.showQuestion = false;
        this.ticketClosed = false;
    }
    Object.defineProperty(ChatComponent.prototype, "activeUserId", {
        get: function () {
            return this._activeUserId;
        },
        set: function (value) {
            this._activeUserId = value;
            this.activeQuestion = null;
            this.questions = null;
            if (value) {
                this.showQuestion = false;
                this.getQuestions(this.activeUserId);
                this.refreshService.setRefreshTimer();
            }
        },
        enumerable: true,
        configurable: true
    });
    ChatComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.refreshSubscription = this.refreshService.emitRefresh.subscribe(function (val) {
            if (val !== null && _this.activeUserId) {
                if (_this.showQuestion) {
                    _this.getDialog(_this.getActiveQuestionUuid(), false);
                }
                else {
                    _this.getQuestions(_this.activeUserId, false);
                }
            }
        });
    };
    ChatComponent.prototype.getQuestions = function (userId, loader) {
        var _this = this;
        if (loader === void 0) { loader = true; }
        if (loader) {
            this.loaderService.show();
        }
        this.chatService.getQuestions(userId).subscribe(function (data) {
            _this.setQuestions(data);
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    ChatComponent.prototype.setQuestions = function (data) {
        try {
            if (data.status) {
                this.ticketClosed = false;
                this.questions = data.result;
                if (data.result.length === 1) {
                    this.showUserQuestionDialog(data.result[0]);
                }
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    ChatComponent.prototype.getDialog = function (uuid, loader) {
        var _this = this;
        if (loader === void 0) { loader = true; }
        if (loader) {
            this.loaderService.show();
        }
        this.chatService.getDialog(uuid).subscribe(function (data) {
            _this.setDialog(data);
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            // this.loaderService.hide();
        });
    };
    ChatComponent.prototype.setDialog = function (data) {
        try {
            if (data.status) {
                this.activeQuestion = data.result ? data.result : [];
                this.userInfo = data.user ? data.user : null;
            }
            this.loaderService.hide();
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
        this.showQuestion = true;
    };
    ChatComponent.prototype.closeTicket = function () {
        var _this = this;
        var uuid = this.getActiveQuestionUuid();
        this.chatService.closeTicket(uuid).subscribe(function (data) {
            _this.closeTicketResult(data);
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    ChatComponent.prototype.closeTicketResult = function (data) {
        try {
            if (data.status) {
                this.ticketClosed = true;
                this.emitRefresh.emit();
            }
            else {
                this.popupComponent.showPopupError({ text: data.message });
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    ChatComponent.prototype.showUserQuestionDialog = function (question) {
        // this.showQuestion = true;
        this.selectedQuestion = question;
        this.activeQuestionUuid = question.uuid;
        this.getDialog(this.activeQuestionUuid);
        this.refreshService.setRefreshTimer();
    };
    ChatComponent.prototype.ngOnDestroy = function () {
        this.refreshSubscription.unsubscribe();
    };
    ChatComponent.prototype.goToQuestions = function () {
        this.activeQuestion = null;
        this.showQuestion = false;
        this.getQuestions(this.activeUserId, false);
    };
    ChatComponent.prototype.getActiveQuestionTitle = function () {
        return this.selectedQuestion.title;
    };
    ChatComponent.prototype.getActiveQuestionUuid = function () {
        return this.selectedQuestion.uuid;
    };
    ChatComponent.prototype.getActiveQuestionStatus = function () {
        return +this.selectedQuestion.status || this.ticketClosed;
    };
    ChatComponent.prototype.getQuestionStatus = function (status) {
        return QUESTION_STATUS[status];
    };
    return ChatComponent;
}());
export { ChatComponent };
