import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MobileMenuService {
    public visible = new BehaviorSubject(true);

    constructor() {}

    show() {
        this.visible.next(true);
    }

    hide() {
        this.visible.next(false);
    }

    toggle() {
        this.visible.next(!this.visible.value);
    }
}
