import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {PANEL_INFO_CONFIG} from "../../config/api";
import {LoaderService} from "../loader.service";

@Injectable({
    providedIn: 'root'
})
export class PanelInfoService {
    private config = PANEL_INFO_CONFIG;

    constructor(private http: HttpClient,
                public loaderService: LoaderService) {}

    getPageInfo() {
        // this.loaderService.show();
        return this.http.post<any>(this.config.info, {});
    }
}
