import {Component, EventEmitter, Input, OnInit, Output,Injectable} from '@angular/core';
import {SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import Swal from "sweetalert2";
import {MaskPipe} from "ngx-mask";
import {MaskApplierService} from "ngx-mask";
import {PROJECT_MESSAGES} from "../../config/project";

@Component({
    selector: 'app-popup',
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.scss']
})

@Injectable({providedIn: 'root'})
export class PopupComponent implements OnInit {

    constructor(private maskApplierService: MaskApplierService) {
    }

    ngOnInit() {

    }

    swalStyled = Swal.mixin({
        customClass: {
            container: 'popup',
            popup: 'popup__inner',
            image: 'popup__image',
            title: 'popup__title',
            content: 'popup__content',
            confirmButton: 'popup__btn popup__btn_confirm button',
            cancelButton: 'popup__btn popup__btn_cancel'},
    });

    showPopupSuccess(message?) {
        this.swalStyled.fire({
            showClass: {
                popup: 'swal-success'
            },
            text: message.text ? message.text : 'The action was successful!',
            confirmButtonText: 'OK, THANKS',
        })
    }

    showPopupConfirmCancel(options, handler, timer = null) {
        let text = options.text ? options.text : 'Something went wrong. Please try again.';
        let style = options.style ? options.style : 'swal-confirm-cancel';
        let closeBtn = options.closeBtn === false ? options.closeBtn : true;
        let confirmButtonText = options.confirmButtonText ? options.confirmButtonText : 'CONFIRM';
        let cancelButtonText = options.cancelButtonText ? options.cancelButtonText : 'CANCEL';

        this.swalStyled.fire({
            showClass: {
                popup: style,

            },
            html: text,
            allowOutsideClick: closeBtn,
            showCancelButton: true,
            cancelButtonText: cancelButtonText,
            showConfirmButton: true,
            confirmButtonText: confirmButtonText,
            timer: timer,
            customClass: {
                container: 'popup',
                popup: 'popup__inner',
                image: 'popup__image',
                title: 'popup__title',
                content: 'popup__content',
                confirmButton: 'popup__btn popup__btn_confirm button',
                input: 'login-input',
                cancelButton: 'popup__btn popup__btn_cancel button'
            }
        }).then((result) => {
            const dismissReason: string = result.dismiss && result.dismiss.toString();

            if (dismissReason === 'timer') {
                return handler('timerOut');
            }

            if (result.isConfirmed) {
                return handler(true);
            } else {
                return handler(false);
            }
        })
    }

    showPopupErrorReturnEvent(message, handler) {
        let text = message.text ? message.text : 'Oops! Something is not right.';
        let confirmButtonText = message.confirmButtonText ? message.confirmButtonText : 'OK';
        let closeBtn = message.closeBtn === false ? message.closeBtn : true;

        this.swalStyled.fire({
            text: text,
            showConfirmButton: closeBtn,
            allowOutsideClick: closeBtn,
            confirmButtonText: confirmButtonText,
        }).then((result) => {
            if (result.isConfirmed) {
                return handler(true);
            }else{
                return handler(false);
            }
        });
    }

    showPopupError(message?) {
        let text = message.text ? message.text : 'Oops! Something is not right.';
        let confirmButtonText = message.confirmButtonText ? message.confirmButtonText : 'BACK';
        let closeBtn = message.closeBtn === false ? message.closeBtn : true;

        this.swalStyled.fire({
            // title: 'Error',
            // imageUrl: '../assets/images/popup/error-logo.png',
            //className: "swal-back",
            showClass: {
                popup: 'swal-error'
            },
            text: text,
            showConfirmButton: closeBtn,
            allowOutsideClick: closeBtn,
            confirmButtonText: confirmButtonText
        })
    }

    showPopupTryAgain() {
        this.swalStyled.fire({
            showClass: {
                popup: 'swal-error'
            },
            text: PROJECT_MESSAGES.unknownError,
            confirmButtonText: 'BACK'
        });
    }

    showPopupAgreement(options, handler) {
        let text = options.text ? options.text : 'Something went wrong. Please try again.';
        let confirmButtonText = options.confirmButtonText ? options.confirmButtonText : 'CONFIRM';
        let cancelButtonText = options.cancelButtonText ? options.cancelButtonText : '<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M25.5 24.1719L18.3281 17L25.5 9.82812C25.8668 9.46136 25.8668 8.86676 25.5 8.5C25.1332 8.13324 24.5386 8.13324 24.1719 8.5L17 15.6719L9.82813 8.5C9.46136 8.13324 8.86676 8.13324 8.5 8.5C8.13324 8.86676 8.13324 9.46136 8.5 9.82812L15.6719 17L8.5 24.1719C8.13324 24.5386 8.13324 25.1332 8.5 25.5C8.86676 25.8668 9.46136 25.8668 9.82813 25.5L17 18.3281L24.1719 25.5C24.5386 25.8668 25.1332 25.8668 25.5 25.5C25.8668 25.1332 25.8668 24.5386 25.5 24.1719Z" fill="#FDFD88"/>\n' +
            '</svg>';
        let showConfirmButton = options.showConfirmButton === false ? options.showConfirmButton : true;

        this.swalStyled.fire({
            showClass: {
                popup: 'swal-agreement',

            },
            html: text,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText,
            showCancelButton: true,
            showConfirmButton: showConfirmButton,
        }).then((result) => {
            if (result.isConfirmed) {
                return handler(true);
            }else{
                return handler(false);
            }
        })
    }

    closePopup() {
        this.swalStyled.close();
    }

}
