import { USER_CHAT_CONFIG } from "../../config/api";
import { RouterPaths } from "../../config/router-paths.model";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "../../modules/auth/_services/authentication.service";
import * as i4 from "../loader.service";
var UserChatService = /** @class */ (function () {
    function UserChatService(http, router, authenticationService, loaderService) {
        this.http = http;
        this.router = router;
        this.authenticationService = authenticationService;
        this.loaderService = loaderService;
        this.config = USER_CHAT_CONFIG;
    }
    UserChatService.prototype.getPageInfo = function (session) {
        // this.loaderService.show();
        return this.http.post(this.config.info, { session: session });
    };
    UserChatService.prototype.getQuestionInfo = function (uuid, full) {
        // this.loaderService.show();
        return this.http.post(this.config.question, { uuid: uuid, full: full });
    };
    UserChatService.prototype.backToUserChat = function () {
        var sessionId = this.authenticationService.getSessionId();
        if (sessionId) {
            this.router.navigate([RouterPaths.userChat.main, String(sessionId)]);
        }
        else {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    UserChatService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserChatService_Factory() { return new UserChatService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.AuthenticationService), i0.ɵɵinject(i4.LoaderService)); }, token: UserChatService, providedIn: "root" });
    return UserChatService;
}());
export { UserChatService };
