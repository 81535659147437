/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./panel-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./panel-header.component";
import * as i4 from "../../modules/auth/_services/authentication.service";
import * as i5 from "../../service/mobile-menu.service";
import * as i6 from "@angular/router";
var styles_PanelHeaderComponent = [i0.styles];
var RenderType_PanelHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PanelHeaderComponent, data: {} });
export { RenderType_PanelHeaderComponent as RenderType_PanelHeaderComponent };
function View_PanelHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "panel-header__logout-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Log Out "]))], null, null); }
export function View_PanelHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "header", [["class", "panel-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "panel-header__col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, ":svg:svg", [["class", "panel-header__logo-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, ":svg:use", [[":xlink:href", "../../../../assets/images/sprite.svg#chat"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "panel-header__menu panel-header__menu--mobile"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleLeftMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, ":svg:svg", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, ":svg:use", [[":xlink:href", "../../../../assets/images/sprite.svg#menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["class", "panel-header__refresh"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refreshPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, ":svg:svg", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, ":svg:use", [[":xlink:href", "../../../../assets/images/sprite.svg#refresh"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "panel-header__col"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PanelHeaderComponent_1)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hideLogOut; _ck(_v, 12, 0, currVal_0); }, null); }
export function View_PanelHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-panel-header", [], null, null, null, View_PanelHeaderComponent_0, RenderType_PanelHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.PanelHeaderComponent, [i4.AuthenticationService, i5.MobileMenuService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PanelHeaderComponentNgFactory = i1.ɵccf("app-panel-header", i3.PanelHeaderComponent, View_PanelHeaderComponent_Host_0, { userName: "userName" }, { refresh: "refresh" }, []);
export { PanelHeaderComponentNgFactory as PanelHeaderComponentNgFactory };
