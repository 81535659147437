import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {Router} from "@angular/router";
import {MobileMenuService} from "../../service/mobile-menu.service";

@Component({
    selector: 'app-panel-header',
    templateUrl: './panel-header.component.html',
    styleUrls: ['./panel-header.component.scss']
})
export class PanelHeaderComponent implements OnInit {
    @Input() userName = '';
    @Output() refresh:EventEmitter<any> = new EventEmitter();
    openMenu = false;
    hideLogOut = false;

    constructor(private authenticationService: AuthenticationService,
                private mobileMenuService: MobileMenuService,
                private router: Router) {}

    ngOnInit() {
        document.addEventListener('click', this.closeMenu.bind(this));
        this.hideLogOut = this.authenticationService.isIframeVision();
    }

    closeMenu(e) {
        if (document.querySelector('.panel-menu')) {
            if (!document.querySelector('.panel-menu').contains(e.target)) {
                this.openMenu = false;
            }
        }
    }

    toggleMenu() {
        this.openMenu =  !this.openMenu;
    }

    refreshPage() {
        this.refresh.emit();
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/']);
    }

    toggleLeftMenu() {
        this.mobileMenuService.toggle();
    }
}
