/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../user-info/user-info.component.ngfactory";
import * as i3 from "../user-info/user-info.component";
import * as i4 from "@angular/common";
import * as i5 from "./dialog.component";
import * as i6 from "../../service/loader.service";
import * as i7 from "../popup/popup.component";
import * as i8 from "../../service/chat/chat.service";
var styles_DialogComponent = [i0.styles];
var RenderType_DialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DialogComponent, data: {} });
export { RenderType_DialogComponent as RenderType_DialogComponent };
function View_DialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-info", [], null, null, null, i2.View_UserInfoComponent_0, i2.RenderType_UserInfoComponent)), i1.ɵdid(1, 114688, null, 0, i3.UserInfoComponent, [], { userInfo: [0, "userInfo"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userInfo; _ck(_v, 1, 0, currVal_0); }, null); }
function View_DialogComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", ""], ["class", "dialog__img"]], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.zoomImage(_v.parent.context.$implicit.file_link) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.file_link; _ck(_v, 0, 0, currVal_0); }); }
function View_DialogComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "dialog__message"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.title; _ck(_v, 1, 0, currVal_0); }); }
function View_DialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "li", [["class", "dialog__item"]], [[2, "dialog__item--right", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "dialog__created"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵppd(3, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DialogComponent_4)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DialogComponent_5)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _v.context.$implicit.file_link; _ck(_v, 5, 0, currVal_2); var currVal_3 = (_v.context.$implicit.title && (_v.context.$implicit.type !== _co.ANSWER_TYPE.file)); _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (((((_v.context.$implicit.role_id - 0) === (_co.USER_ROLE.admin - 0)) && !_co.isCustomer) || (((_v.context.$implicit.role_id - 0) === (_co.USER_ROLE.adminRedirect - 0)) && !_co.isCustomer)) || (((_v.context.$implicit.role_id - 0) === (_co.USER_ROLE.customer - 0)) && _co.isCustomer)); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.created, _co.DATE_MASK.full)); _ck(_v, 2, 0, currVal_1); }); }
function View_DialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[2, 0], ["scrolled", 1]], null, 4, "ul", [["class", "dialog"]], [[2, "is-answer", null], [2, "is-client", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DialogComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DialogComponent_3)), i1.ɵdid(4, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (!_co.isCustomer && _co.userInfo); _ck(_v, 2, 0, currVal_2); var currVal_3 = _co.activeQuestion; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.getActiveQuestionStatus(); var currVal_1 = _co.isCustomer; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_DialogComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "answer__uploaded-file"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" File: "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeFile(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, ":svg:svg", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, ":svg:use", [[":xlink:href", "../../../../assets/images/sprite.svg#close"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_0); }); }
function View_DialogComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "answer"]], [[2, "is-user-chat", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DialogComponent_7)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "answer__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "answer__upload-image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, [["addImage", 1]], null, 0, "input", [["accept", "image/*"], ["hidden", ""], ["multiple", ""], ["name", "filename"], ["style", "display: none"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.handleFileInput($event.target.files) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, ":svg:svg", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, ":svg:use", [[":xlink:href", "../../../../assets/images/sprite.svg#image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, [[1, 0], ["message", 1]], null, 0, "input", [["class", "answer__input"], ["placeholder", "Type a message..."], ["type", "text"]], null, [[null, "paste"], [null, "keyup.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("paste" === en)) {
        var pd_0 = (_co.screenShort($event) !== false);
        ad = (pd_0 && ad);
    } if (("keyup.enter" === en)) {
        var pd_1 = (_co.sendMessage() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "answer__submit"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sendMessage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Send"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.fileToUpload; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isCustomer; _ck(_v, 0, 0, currVal_0); var currVal_2 = (!_co.fileToUpload && !i1.ɵnov(_v, 9).value); _ck(_v, 10, 0, currVal_2); }); }
export function View_DialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.DatePipe, [i1.LOCALE_ID]), i1.ɵqud(671088640, 1, { message: 0 }), i1.ɵqud(671088640, 2, { scrolled: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DialogComponent_1)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DialogComponent_6)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeQuestion; _ck(_v, 4, 0, currVal_0); var currVal_1 = !_co.getActiveQuestionStatus(); _ck(_v, 6, 0, currVal_1); }, null); }
export function View_DialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog", [], null, null, null, View_DialogComponent_0, RenderType_DialogComponent)), i1.ɵdid(1, 114688, null, 0, i5.DialogComponent, [i6.LoaderService, i7.PopupComponent, i8.ChatService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DialogComponentNgFactory = i1.ɵccf("app-dialog", i5.DialogComponent, View_DialogComponent_Host_0, { userInfo: "userInfo", activeQuestion: "activeQuestion", activeUserId: "activeUserId", activeQuestionUuid: "activeQuestionUuid", selectedQuestion: "selectedQuestion", isCustomer: "isCustomer" }, { updateDialog: "updateDialog" }, []);
export { DialogComponentNgFactory as DialogComponentNgFactory };
