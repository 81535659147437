import {Component, OnInit} from '@angular/core';
import {slideToLeft} from "../../animations/router.animations";
import {PanelInfoService} from "../../service/panel-info/panel-info.service";
import {LoaderService} from "../../service/loader.service";
import {PopupComponent} from "../../shared/popup/popup.component";
import {PanelInfoDataModel, PanelInfoDataUserModel} from "../../models/panel-info/panelInfoData.model";
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {RefreshService} from "../../service/refresh.service";
import {MobileMenuService} from "../../service/mobile-menu.service";

@Component({
    selector: 'app-panel',
    templateUrl: './panel.component.html',
    styleUrls: ['./panel.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class PanelComponent implements OnInit {
    usersNew: Array<PanelInfoDataUserModel> = [];
    usersOld: Array<PanelInfoDataUserModel> = [];
    activeUser: PanelInfoDataModel;
    activeUserId: number;
    refreshSubscription;
    showMobileMenu = false;
    constructor(private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private refreshService: RefreshService,
                private mobileMenuService: MobileMenuService,
                private authenticationService: AuthenticationService,
                private panelInfoService: PanelInfoService) {
        this.mobileMenuService.visible.subscribe(val => {
            this.showMobileMenu = val;
        });
    }

    ngOnInit() {
        this.setRefreshTimer();
        this.getPageInfo();
    }

    setRefreshTimer() {
        this.refreshSubscription = this.refreshService.emitRefresh.subscribe(val => {
            if (val !== null) {
                this.getPageInfo(false);
            }
        });
        this.refreshService.setRefreshTimer();
    }

    ngOnDestroy() {
        if (this.refreshSubscription) {
            this.refreshSubscription.unsubscribe();
        }
    }

    getPageInfo(loader = true) {
        const refresh = !loader;
      if (loader) {
        this.loaderService.show();
      }
        this.panelInfoService.getPageInfo().subscribe(data => {
            this.setPageInfo(data, refresh);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    setPageInfo(data: PanelInfoDataModel, refresh = false) {
        try {
            if (data.status) {
                if (data) {
                    if (refresh) {
                        if ((data.new && this.usersNew.length !== data.new.length) ||
                            (data.old && this.usersOld.length !== data.old.length)) {
                            this.setUsers(data)
                        }
                    } else {
                        this.setUsers(data)
                    }
                }
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    setUsers(users) {
        this.usersNew = users.new ? users.new : [];
        this.usersOld = users.old ? users.old : [];
    }

    getUserName() {
        return this.authenticationService.getUserFullName();
    }

    showUserQuestions(user) {
        this.activeUserId = user.user_id;
        this.activeUser = user;
        this.mobileMenuService.hide();
    }

    refreshPage() {
        this.refreshService.refresh();
    }
}
