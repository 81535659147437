import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AuthenticationService} from "./modules/auth/_services/authentication.service";
import {PopupComponent} from "./shared/popup/popup.component";
import {AppVersionrService} from "./service/app-version/appVersionr.service";
import {fromEvent} from "rxjs";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    login = false;
    isConnected = true;
    noInternetConnection = false;

    constructor(private router: Router,
                private authenticationService: AuthenticationService,
                private popupComponent: PopupComponent,
                private appVersionService: AppVersionrService) {
        fromEvent(window, 'online').subscribe((resp: any) => {
            this.isConnected = true;
            this.noInternetConnection = false;
            this.popupComponent.closePopup();
        });

        fromEvent(window, 'offline').subscribe((resp: any) => {
            this.isConnected = false;
            this.noInternetConnection = true;
            this.popupComponent.showPopupError({text: 'No Internet Connection: please, try to connect to the Internet', closeBtn: false})
        });
    }

    ngOnInit() {
        // this.setCorrectThumbnailIcon();
        this.refreshToken();
        this.appVersion();
        this.authenticationService.setStamp();
        document.addEventListener('visibilitychange', () => {
            this.authenticationService.checkStamp();
        });
        if (this.isIos()) {
            document.body.classList.add('is-ios');
        }
        window.addEventListener('resize', this.appHeight);
        this.appHeight();
    }

    ngOnDestroy() {}

    refreshToken(){
        let $this = this;
        setTimeout(function(){
            $this.authenticationService.refreshToken();
        },60*5*1000);
    }

    loginChanged(status){
        this.login = status;
    }

    appVersion(){
        this.appVersionService.checkVersion();
    }

    isIos(){
        let userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test( userAgent );
    }

    appHeight() {
        const doc = document.documentElement;
        doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }

    setCorrectThumbnailIcon() {
        this.changeThumbnailIcon('link[rel="icon"]', 'icon-72x72.png');
        this.changeThumbnailIcon('link[rel="apple-touch-icon"][sizes="180x180"]', 'icon-180x180.png');
        this.changeThumbnailIcon('link[rel="apple-touch-icon"][sizes="120x120"]', 'icon-120x120.png');

        this.changeTitle();
    }

    changeThumbnailIcon(selector, icon) {
        const defaultIcon = document.querySelector(selector).getAttribute('href');
        const newIcon = defaultIcon.split('/icons')[0] + `/images/partners/riverPay/${icon}`;
        document.querySelector(selector).setAttribute('href', newIcon);
    }

  changeTitle() {
    // const isRiverPay = this.partnerService.isRiverPay();
    // const partner = isRiverPay ? PARTNERS_INFO.riverPay.name : PARTNERS_INFO.default.name;
    //
    // document.querySelector("title").innerHTML = `${partner} POS`
  }
}


