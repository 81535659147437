import {Inject, Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AuthConfig} from '../_services/auth-config';
import {AuthenticationService} from "../_services/authentication.service";
import {LoaderService} from "../../../service/loader.service";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {environment} from "../../../../environments/environment";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  hasError = false;
  refreshingToken = false;

  constructor(@Inject(AuthConfig) private authConfig: AuthConfig,
              private authenticationService: AuthenticationService,
              private loaderService: LoaderService,
              private popupComponent: PopupComponent,
              private router: Router) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // ////console.log(request);
    // ////console.log(next);
    if (this.authConfig.login === request.url) {
      this.hasError = false;
      this.refreshingToken = false;
    }

    while (this.refreshingToken) {

    }
    if (this.hasError && this.authConfig.login !== request.url) {
      return null;
    }
    let storage = sessionStorage;
    // add authorization header with jwt token if available
    const currentUser = this.authenticationService.getCurrentUser();
    const sessionId = this.authenticationService.getSessionId();
    // ////console.log(request.url);

      request = request.clone({
          setHeaders: {
              Partner: `${window.location.hostname}`
          }
      });

    if (currentUser) {
      const token = this.authenticationService.getToken();
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
      }

    }

    if (sessionId && !environment.frontEndTest) {
      request = request.clone({
        setHeaders: {
          Session: `${sessionId}`
        }
      });
    }

    if (this.authConfig.refresh === request.url) {
      this.refreshingToken = true;
    }

    return next.handle(request).pipe(
      tap( // Log the result or error
          (data:any) => {
            try{
                if(data != undefined) {
                    if (data.body) {
                        if (data.body.code) {
                            if (data.body.code == 401) {
                              if (!sessionId) {
                                this.authenticationService.logout();
                                this.router.navigate(['/'], {queryParams: {notLogged: true}});
                                //this.router.navigate([this.authConfig.login], {queryParams: {returnUrl: ""}});
                              }
                            }
                        }
                    }
                }
            }catch (e) {

            }

          if (this.authConfig.refresh === request.url) {
            this.refreshingToken = false;
          }
        },
        error => {
            this.loaderService.hide();

          if (this.authConfig.refresh === request.url) {
            this.refreshingToken = false;
            this.hasError = true;
          }else{
            if (!sessionId) {
              this.authenticationService.logout();
              this.router.navigate(['/'], {queryParams: {notLogged: true}});
            }

              //this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
          }

        }
      )
    );
  }
}
